import React from "react";
import NavHeader from "../../../components/NavHeader";

const Merger = () => {
  return (
    <div>
      <NavHeader name={"Merger & Acquisitions"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2021/07/M-A-Merger.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Merger & Acquisitions</h3>
                <ul>
                  <li>Investment Banking </li>
                  <li>Handling the end to end due diligence process</li>
                  <li>Strategic and implementation support</li>
                  <li>Wealth Management</li>
                  <ol>
                    <li>Fund Raising</li>
                    <li>Private Equity and Venture Capital</li>
                    <li>Debt Funding and Advisory </li>
                  </ol>
                  <li>Debt Restructuring</li>
                  <li>To Prepare Grounds For Mergers and Acquisitions</li>
                  <li>Company Valuation For Mergers and Acquisitions</li>
                  <li>Protection From Legal Action Taken By Employees</li>
                  <li>Mediation With Rival Parties</li>
                  <li>Determination of Tax Liabilities</li>
                  <li>Consultancy in above matters</li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Merger;
