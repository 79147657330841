import React from "react";
import NavHeader from "../../../components/NavHeader";
import { useLocation } from "react-router-dom";
import { ContentSection } from "../../../components/ContentSection";

export const CommonPage = () => {
  const { state } = useLocation();
  console.log("State", state);
  return (
    <div>
      <NavHeader name={state.data.title} />
      <ContentSection item={state.data} />
    </div>
  );
};
