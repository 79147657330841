import React from "react";
import NavHeader from "../../../components/NavHeader";

const CompanySecretary = () => {
  return (
    <div>
      <NavHeader name={"COMPANY SECRETARY"} />
      <section
        class="collections-gid-janus pt-5 pb-5"
        style={{ background: "#f0f0f0" }}
      >
        <div class="container patn">
          <div class="row borders">
            <div class="col-lg-3  px-lg-0">
              <div class="founder-partners-img">
                <img
                  src="https://gkkediaandco.com/wp-content/uploads/2022/09/CS-DEEPIKA-BABBAR.jpg"
                  alt="Company Secretary"
                  class="img-fluid image1"
                />
              </div>
            </div>
            <div class="col-lg-6 progress-content-img px-lg-0 p-lg-5">
              <div class="founder-partners-img service-call">
                <h3 class="title-w3pvt2 mb-1">CS Deepika Babbar</h3>
                <ul>
                  <li>B.Com, ACS</li>
                  <li>M. No. 51428</li>
                  <li>Manager - Corporate Law</li>
                  <li style={{ fontSize: 13 }}>E-mail: roc@gkkedia.com</li>
                </ul>
              </div>
            </div>
            <div class="founder-team-details service-call pt-4">
              <h2 class="title-w3pvt2 mb-2">Background & Experience </h2>
              <p>
                CS Deepika Babbbar is a Commerce graduate and Company Secretary
                and qualified as CS in June, 2016. She has 6 years of expertise
                in the field of Company Law Compliances, Secretarial Audit, RBI
                related Matters & other regulatory matters. She is also having
                experience in the field of Taxation, Intellectual Property
                Rights, FSSAI, Secretarial Audit and Internal Audit & Assurance.
                She has also been widely involved in drafting & vetting of
                contractual agreements & providing implementation support for
                the aforementioned. CS Deepika Babbar has also spearhead areas
                of several share valuation exercises for business.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompanySecretary;
