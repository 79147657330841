import React from "react";
import NavHeader from "../../components/NavHeader";
import "./style.css";
import { Partners } from "../../constant/servicesData";
import { useNavigate } from "react-router-dom";

const Resources = () => {
  const navigate = useNavigate();
  return (
    <div>
      <NavHeader name={"Professional Consultant"} />
      <section class="collections-gid-janus pt-5 pb-5">
        <div class="container patn">
          <div class="row">
            {Partners.map((item) => {
              return (
                <div class="col-lg-6">
                  <div
                    class=" borders partners-team2 patn"
                    style={{ minHeight: 250 }}
                  >
                    <div class="row">
                      <div class="col-lg-4 progress-content-img founder-partners-img-width">
                        <div class="founder-partners-img">
                          <img
                            src={item.image}
                            alt="CA Sangeeta Singh"
                            class="img-fluid image1"
                          />
                        </div>
                      </div>
                      <div class="col-lg-8 progress-content-img">
                        <div class="founder-partners-img2">
                          <h3 style={{ fontSize: 24, textAlign: "left" }}>
                            {item.name}
                          </h3>
                          <ul style={{ marginTop: 20 }}>
                            <li>{item.qualification}</li>
                            {/* <li>M. No. {item.MNo}</li> */}
                            <li>{item.post}</li>
                            {/* <li>Mobile : {item.mobile}</li> */}
                            {/* <li>E-mail: {item.email}</li> */}
                          </ul>
                        </div>
                        {/* <div class="btn btn-dark btn--leftBorder btn--rightBorder  mt-3">
                          <a
                            onClick={() =>
                              navigate(
                                `/partners/${item.name}`.replace(/\s/g, ""),
                                {
                                  state: { data: item },
                                }
                              )
                            }
                          >
                            Explore More
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Resources;
