import React from "react";
import NavHeader from "../../../components/NavHeader";

const Trademarks = () => {
  return (
    <div>
      <NavHeader name={"TRADEMARKS"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2021/07/rubber-stamps-with-Trandmark.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">TRADEMARKS</h3>
                <ul>
                  <li>
                    Exploring new Trademarks and study to scrutinize if the
                    proposed trademark is already in use
                  </li>
                  <li>
                    Advising on Trademark availability for use and/ or
                    registration
                  </li>
                  <li>
                    Groundwork for all procedural framework required in
                    Trademark registration
                  </li>
                  <li>
                    Filing and processing of application for Registration of
                    Trademark
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Trademarks;
