import React from "react";
import NavHeader from "../../../components/NavHeader";

const Income = () => {
  return (
    <div>
      <NavHeader name={"Income Tax"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="	https://gkkediaandco.com/wp-content/uploads/2019/06/Income-Tex.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Income Tax</h3>
                <p>
                  The tax landscapes have changed a great deal over the years,
                  and the complexity of change in the tax scenario continues to
                  grow at a fast pace. This change in the corporate and private
                  space has brought about a greater shift in the way Tax
                  authorities work, and therefore it is necessary to have the
                  right procedures in place when it comes to taxes.
                </p>
                <ul>
                  <li>Preparation of Income Tax Return</li>
                  <li>
                    Rectification of Proposed Adjustments in Income Tax Returns
                  </li>
                  <li>
                    Faceless Assessment (Scrutiny) of Tax Returns and Penalty
                    Proceedings
                  </li>
                  <li>Consultancy in Income Tax matters &#038; Tax Planning</li>
                  <li>Specified Financial Transactions (Form 61A, Form 61B)</li>
                  <li>Income Tax Audit u/s 44 AB</li>
                  <li>Form 10B Audit in case of NGO, 10BB Audit</li>
                  <li>Ex-patriate Taxation</li>
                  <li>
                    Tax Evasion Petitions, CPGRAMS, raising grievances for long
                    pending matters
                  </li>
                  <li>Assistance in Equilization Levy Compliances</li>
                  <li>Preparation and representation before authorities</li>
                  <li>
                    Representation before authorities &#8211; Compounding of
                    Offences in TDS{" "}
                  </li>
                  <li>Quarterly Statement in Form15CC (Rule 37BB)</li>
                  <li>International taxation consultancy</li>
                  <li>
                    Transfer Pricing matters-TP Study, TP Audit, TP Assessment
                    &#038; Objections with DRP
                  </li>
                  <li>TDS &#038; Withholding tax compliance</li>
                  <li>
                    Lower or No TDS Deduction Certificate related consultancy
                  </li>
                  <li>
                    Income tax Faceless Appeals before Commissioner of Income
                    Tax (Appeals)
                  </li>
                  <li>Representation before Dispute Resolution Committee</li>
                  <li>
                    Income tax Appeals before Income Tax Appellate Tribunal
                    (ITAT)
                  </li>
                  <li>Survey, Search & Seizer Consultancy</li>
                  <li>
                    Appearance before Investigation Wing of Income Tax
                    Department (Section 153A to 153C cases)
                  </li>
                  <li>
                    Appearance before Central Board of Direct Taxes (CBDT) and
                    before Ministry of Finance
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Income;
