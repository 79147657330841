import React from "react";
import NavHeader from "../../../components/NavHeader";

const GST = () => {
  return (
    <div>
      <NavHeader name={"Goods & Services Tax (GST)"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/GST.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Goods & Services Tax (GST)</h3>
                <p>
                  The GST journey in India began in the year 2017, and since
                  then, it has come a long way in shaping our economy. All
                  matters like registration, form filing, application for
                  refund, etc. are taken care of by our skilled and efficient
                  Chartered Accountants . It is our sole purpose and duty to
                  take part in and to give way to a robust and open discussion
                  surrounding accountable GST, increased transparency and the
                  ensuring of taxation in an increasingly digitized and
                  globalized world.
                </p>
                <p>
                  <strong>GST Registration</strong>
                </p>
                <ul>
                  <li>GST Registration for new Assessee</li>
                </ul>
                <p style={{ marginTop: 20 }}>
                  <strong>Regular Routine Work</strong>
                </p>
                <ul>
                  <li>Filing of monthly, quarterly and Annual GST returns</li>
                  <li>
                    Assistance in deposit of GST to electronic cash ledger
                  </li>
                  <li>Consultancy on GST on regular basis</li>
                  <li>Refund of GST from GST Department</li>
                  <li>
                    Bond and Letter of Undertaking (LUT) related work for
                    Exporters
                  </li>
                  <li>Assisting in Internal Audit by GST Department</li>
                  <li>
                    Assisting and Representation in Show Cause Notice issued by
                    Superintendent, GST Audit Department
                  </li>
                  <li>Search Cases under GST</li>
                  <li>Contract review from GST perspective</li>
                  <li>
                    Representation before relevant authority for GST appeal i.e.
                    Commissioner, CESTAT
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GST;
