import React, { useRef } from "react";
import { ServicesData, ServicesData2 } from "../../constant/servicesData";
import "./style.css";

const OurServices = () => {
  const windowSize = useRef([window.innerWidth]);
  console.log("windowSize", windowSize);
  return (
    <div>
      <section className="services-section-two margin-top">
        <div className="container">
          <div className="upper-box">
            <div className="sec-title light centered">
              <h2>OUR SERVICES</h2>
            </div>
          </div>

          <div className="inner-container">
            <div className="row2 clearfix">
              <ul
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginInlineEnd: 20,
                }}
              >
                {ServicesData}
              </ul>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginInlineEnd: 20,
                }}
              >
                {ServicesData2}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurServices;
