import React from "react";
import NavHeader from "../../../components/NavHeader";

const Business = () => {
  return (
    <div>
      <NavHeader name={"Business Process Outsource (BPO)"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/BUSINESS-PROCESS-OUTSOURCE-BPO.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Business Process Outsource (BPO)</h3>
                <p>
                  Outsourcing has gone through so many reforms in the last
                  couple of decades and has given way to a whole new economic
                  landscape in India. At P Dhaval &#038; Co., we help our
                  clients and empower their outsourcing businesses with new age
                  digital tools and processes that help them move forward in
                  accomplishing their goals. We believe in modernizing the
                  shared service operations through robust budgeting, management
                  reporting, and treasury. Get in touch with us Now, to see how
                  we can help you with your BPO venture and business.{" "}
                </p>
                <ul>
                  <li>
                    Book-keeping for clients located at USA, UK, Canada,
                    Australia &#038; other countries{" "}
                  </li>
                  <li>Pay roll processing</li>
                  <li>Bank reconciliation statement</li>
                  <li>Accounts Receivables and Payables reconciliation</li>
                  <li>HR Services</li>
                  <li>
                    Preparation and Review of US Tax Returns i.e FORM 1040 ,
                    1040NR ,1065 ,1120 , 1120S etc.
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Business;
