import React, { useRef } from "react";
import "./style.css";

const WhyWeAre = () => {
  return (
    <section
      class="about py-md-5 py-5 rs-about-lawyer rs-about-lawyer1"
      id="about"
      style={{ backgroundColor: "#f5f5f5" }}
    >
      <div class="container">
        <div class="row rs-vertical-middle">
          <div class="col-lg-12  wow fadeInLeft" data-wow-duration="1s">
            <div class="rs-left-border-title">
              <h2 class="title-w3pvt mb-3" style={{ color: "black" }}>
                Why we are the best Chartered Accountant
              </h2>
            </div>
            <div
              class="des"
              //   style="overflow:auto; height:140px; padding:0px 20px 10px 0px"
            >
              <p>
                we offer a one-stop solution for your business needs under one
                roof. With our modern approach, professional competence, and
                results-oriented mindset as an emerging firm, we're your trusted
                partner in navigating the complexities of today's business
                landscape. Explore how we can streamline and elevate your
                business operations at our place, making your success
                our top priority.
              </p>
              {/* <p>
                P Dhaval & Co. is a Ahmedabad based CA firm, founded in 2021. We
                are committed to be the best CA in Ahmedabad who provides
                Accountancy, Income Tax and Consultancy services to all its
                clients across India, with a robust team of dedicated
                professionals. We possess expertise across all financial
                services related to Accounting, Auditing and Assurance, Income
                Tax, GST, Company Law Matters, Start-ups, Societies and Trusts,
                Foreign Exchange Management, Imports, Exports, Finance, Business
                Process Outsource, etc. We provide the best end-to-end solutions
                to our clients by creating a single window for the entire array
                of financial services.
              </p>
              <p>
                Our team consists of the best Chartered Accountants in India who
                are known for their solution-based and result-oriented approach
                towards their clients. Guided by the values of integrity,
                professionalism, honesty, dedication and accountability, we
                ensure that we meet all of our clients’ expectations.
              </p>
              <p>
                With the team of the best Chartered Accountants in Ahmedabad, we
                are always transparent in our dealings and services and make
                sure that we are abreast of the latest technology and software
                in business management. We also revise and improve our latest
                legal books collection periodically to make sure that our staff
                is always aware of all the latest changes happening in the
                financial field.
              </p>
              <p>
                We understand that no two clients are the same, and hence our
                approach is to customize and tailor-make our services in
                accordance with their different business demands and needs. Our
                experienced professionals, also regarded as the best CA in
                India, oversee all the client affairs themselves to get a clear
                understanding of our customers' needs and expectations, in order
                to deliver the best tailor-made services.
              </p>
              <p>
                So, if you are looking for the best Chartered Accountants in
                Ahmedabad then your search stops here...
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhyWeAre;
