import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/footer/Footer";
import Home from "./screens/Home";
import SideSocialMenu from "./components/SideSocialMenu";
import About from "./screens/AboutUs";
import Services from "./screens/Services";
import OurTeam from "./screens/OurTeam";
import Infrastructure from "./screens/Infrastructure";
import Career from "./screens/Career";
import Gallery from "./screens/Gallery";
import Resources from "./screens/Resources";
import Contact from "./screens/Contact";
import Journey from "./screens/AboutUs/Journey & Milestone";
import Auditing from "./screens/Services/Auditing & Assurance";
import Income from "./screens/Services/Income Tax";
import { CommonPage } from "./screens/Services/Common Page";
import InternationalTaxation from "./screens/Services/InternationalTaxation";
import GST from "./screens/Services/GOODS & SERVICES TAX (GST)";
import Company from "./screens/Services/COMPANY LAW MATTERS CONSULTANCY";
import Merger from "./screens/Services/Merger & Acquisitions";
import Forensic from "./screens/Services/Forensic Audit Services";
import Due from "./screens/Services/DUE DILIGENCE SERVICES";
import Secretarial from "./screens/Services/SECRETARIAL SERVICES";
import Trademarks from "./screens/Services/TRADEMARKS";
import Accounting from "./screens/Services/ACCOUNTING";
import Investment from "./screens/Services/INVESTMENT";
import Startup from "./screens/Services/STARTUP SERVICES";
import Societies from "./screens/Services/SOCIETIES AND TRUST (NGO)";
import Foreign from "./screens/Services/FOREIGN EXCHANGE MANAGEMENT ACT";
import Founder from "./screens/OurTeam/Founder Partner";
import { PartnerCommonPage } from "./screens/Resources/PartnerCommonPage";
import Chartered from "./screens/OurTeam/Chartered Accountants";
import CompanySecretary from "./screens/OurTeam/Company Secretary";
import KeyStaff from "./screens/OurTeam/KeyStaff";
import Blogs from "./screens/Resources/Blogs";
import Important from "./screens/Resources/Important Link";
import Empanelment from "./screens/Resources/Empanelment";
import Live from "./screens/Resources/Live Updates";
import Finance from "./screens/Services/Finance";
import Business from "./screens/Services/Business Process Outsource (BPO)";
import CACertification from "./screens/Services/CA Certification";
import BusinessConsultant from "./screens/OurTeam/Business Consultant";

function App() {
  return (
    <div className="App">
      <SideSocialMenu />
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/career" element={<Career />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/partners" element={<Resources />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/journey-and-milestones" element={<Journey />} />
          <Route path="/services/auditing-assurance" element={<Auditing />} />
          <Route path="/services/income-tax" element={<Income />} />
          <Route
            path="/services/international-taxation/"
            element={<InternationalTaxation />}
          />
          <Route path="/services/goods-services-tax-gst/" element={<GST />} />
          <Route
            path="/services/company-law-matters-consultancy/"
            element={<Company />}
          />
          <Route path="/services/merger-acquisitions/" element={<Merger />} />
          <Route
            path="/services/forensic-audit-services/"
            element={<Forensic />}
          />
          <Route path="/services/due-diligence-services/" element={<Due />} />
          <Route
            path="/services/secretarial-services-sebi-related-work/"
            element={<Secretarial />}
          />
          <Route path="/services/trademarks/" element={<Trademarks />} />
          <Route path="/services/accounting/" element={<Accounting />} />
          <Route path="/services/finance/" element={<Finance />} />
          <Route
            path="/services/business-process-outsource-bpo/"
            element={<Business />}
          />
          <Route
            path="/services/ca-certification/"
            element={<CACertification />}
          />
          <Route
            path="/services/investment-in-india-by-foreign-nationals-nris/"
            element={<Investment />}
          />
          <Route path="/services/startup-services/" element={<Startup />} />
          <Route
            path="/services/societies-and-trust-ngo/"
            element={<Societies />}
          />
          <Route
            path="/services/foreign-exchange-management-act/"
            element={<Foreign />}
          />
          <Route path="/services/:id" element={<CommonPage />} />
          <Route path="/founder-partner/" element={<Founder />} />
          <Route path="/partners/:id" element={<PartnerCommonPage />} />
          <Route
            path="/chartered-accountants-in-delhi/"
            element={<Chartered />}
          />
          <Route path="/company-secretary/" element={<CompanySecretary />} />
          <Route path="/key-staff/" element={<KeyStaff />} />
          <Route path="/blogs/" element={<Blogs />} />
          <Route path="/important-link/" element={<Important />} />
          <Route path="/empanelment/" element={<Empanelment />} />
          <Route path="/live-updates/" element={<Live />} />
          <Route
            path="/business-consultant/"
            element={<BusinessConsultant />}
          />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
