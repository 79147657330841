import React from "react";
import "./style.css";
import * as Icon from "react-bootstrap-icons";
import {
  followUs,
  ourServices,
  paymentMode,
  quickLinks,
} from "../../constant/footerData";

const Footer = () => {
  return (
    <>
      <footer className="footer-content">
        <div className="container py-md-3">
          {/* <div className="row">
            <div className="col-4 col-sm-4">
              <div className="years">
                <a href="">
                  {" "}
                  <img
                    src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/29-year-icons.png"
                    alt=""
                    className="op-img img-responsive"
                  />
                </a>
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="hd-cantt text-center">
                <a
                  href=""
                  className="h_padding-left MediaCoverageFooter"
                  id="MediaCoverageFooter"
                >
                  <Icon.Tv color="white" size={50} />
                  <p className="footer-title-large">
                    <span>Media </span> <br />
                    Coverage
                  </p>
                </a>
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="hd-cantt text-right">
                <a
                  href=""
                  className="h_padding-right reg EnquireNowfooter"
                  id="EnquireNowfooter"
                >
                  <Icon.Envelope color="white" size={50} />
                  <p className="footer-title-large ">
                    <span>Enquire</span> <br />
                    Now
                  </p>
                </a>
              </div>
            </div>
          </div>
          <hr className="footer-line-b" />
          <div className="row">
            <div className="col-xs-12 col-sm-2"></div>
            <div
              className="col-xs-12 col-sm-5"
              style={{
                // display: "flex",
                width: 500,
                // background: "Red",
              }}
            >
              <div className="fotter-sms-col sms-divider">
                <Icon.TelephoneFill color="white" size={20} />
                <p>
                  <span>
                    <a
                      href="tel:+911234567890"
                      id="phonecallfooter"
                      className="phonecallfooter"
                    >
                      +91-12-34567890
                    </a>
                  </span>
                  <text style={{ color: "white" }}> , </text>
                  <span>
                    <a
                      href="tel:1800110099"
                      id="tollfreecallfooter"
                      className="tollfreecallfooter"
                    >
                      1800 1234 56
                    </a>
                  </span>{" "}
                  <text style={{ color: "white" }}> (India Toll free)</text>
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4">
              <div
                className="fotter-sms-col sms-divider pull-right"
                // style={{ flexDirection: "row", display: "flex" }}
              >
                <Icon.EnvelopeFill color="white" size={20} />

                <p>
                  <a href="mailto:mail@caweb.com" id="mailfooter">
                    mail@caweb.com
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
          <hr className="footer-line-b" /> */}

          <div className="row footer-top-inner-janus">
            <div className="col-lg-3 col-md-4 mt-lg-0 mt-4">
              <div className="footer-lavi">
                <h3 className="mb-3 lavi_title">Our Services</h3>
                <hr />
                <ul>{ourServices}</ul>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-4 mt-lg-0 mt-4">
              <div className="footer-lavi" style={{ marginTop: 60 }}>
                <h3 className="mb-3 lavi_title d-none d-sm-block"></h3>
                <ul>{middleSections}</ul>
                <div className="clearfix"></div>
              </div>
            </div> */}
            <div className="col-lg-3 col-md-4 mt-lg-0 mt-4">
              <div className="footer-lavi">
                <h3 className="mb-3 lavi_title">Quick Link</h3>
                <hr />
                <ul>{quickLinks}</ul>
              </div>
              {/* <div
                className="paynow d-none d-sm-block"
                style={{ marginTop: 30 }}
              >
                <h3 className="mb-3 lavi_title">Pay By</h3>
                <hr />
                <ul style={{ display: "flex", flexDirection: "row" }}>
                  <li>
                    <a
                      href=""
                      id="scan-paytm-qr-code"
                      className="scan-paytm-qr-code"
                    >
                      {" "}
                      <img
                        src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/paytm-icon.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="" id="pay-now" className="pay-now">
                      {" "}
                      <img
                        src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/onlinepay.png"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-lg-2 col-md-4 mt-lg-0 mt-4">
              <div className="footer-lavi">
                <h3 className="mb-3 lavi_title">Follow Us</h3>
                <hr />
                <div className="social">
                  <ul className="footer-social-media clearfix">{followUs}</ul>
                </div>
                {/* <h3 className="mb-3 mt-3 lavi_title">Payment Mode</h3>
                <hr />
                <ul>{paymentMode}</ul> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copy-right">
        <div className="container" style={{ marginTop: 20 }}>
          <div className="row">
            <div className="col-md-7">
              <p className="copy-right-grids">
                {" "}
                © P Dhaval & Co. | All Rights Reserved | Powered by{" "}
                <a
                  // href=""
                  style={{ color: "white", textDecoration: "none" }}
                >
                  HRinfotech
                </a>
              </p>
              {/* <div className="text-right"><a href="#home" className="move-top"> <span className="fa fa-angle-up" aria-hidden="true"></span></a></div> */}
            </div>
            <div className="col-md-5" style={{ marginBottom: 15 }}>
              <div className="copy-right-grids">
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <li>
                    <a
                      // href=""
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Refund Return Policy
                    </a>
                  </li>
                  <li>
                    <a
                      // href=""
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="back-to-tops whtsapp ">
          <a
            href="https://api.whatsapp.com/send?phone=+919924242073&text=Hello P Dhaval and Co.!"
            id="whatsappicon"
            className="whatsappicon"
          >
            <img
              src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/whatsapp-icon.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
