import React from "react";
import NavHeader from "../../components/NavHeader";
import "./style.css";

const Infrastructure = () => {
  return (
    <div>
      <NavHeader name={"Infrastructure"} />
      <section
        className="collections-gid-janus pt-5 pb-5 infra "
        id="boxscroll"
        style={{ background: "#f0f0f0" }}
      >
        <div className="container">
          <div className="row borders">
            <div className="col-lg-5 p-md-2 p-3">
              <img
                src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/infra.jpg"
                alt=""
                className="img-fluid image1"
              />
            </div>
            <div
              className="col-lg-7 p-md-3 p-3 progress-content text-left wow fadeInRight"
              data-wow-duration="1s"
            >
              <div className="py-lg-3 ">
                <h3 className="title-w3pvt2 mb-4">Office Locations </h3>
                <p>
                  Our head office is situated at the heart of New Delhi, i.e. at
                  Kasturba Gandhi Marg, Connaught Place and our branch office is
                  situated at Pahar Ganj which is adjoining to Connaught Place.
                  We also have our branch office in Melbourne, Australia from
                  where we provide services to the clients located in Australia
                  and in other countries. We also have our branch office in
                  Muktsar - Punjab, Faridabad - Haryana and Raipur -
                  Chhattisgarh.
                </p>
                <p>
                  Floor area of our Head office in Connaught Place is 3000 sq.
                  feet and of our Pahar Ganj Branch office is 3000 sq. feet. Our
                  both the offices are centrally Air Conditioned and are fully
                  equipped with computers, EPBX, Leased Line Internet connection
                  and all the latest legal books.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row borders">
            <div
              className="col-lg-8  p-md-3 p-3 progress-content text-left  wow fadeInRight"
              data-wow-duration="1s"
            >
              <div className="py-lg-3">
                <h3 className="title-w3pvt2 mb-4">Quality Accreditation </h3>
                <p>
                  We have International accreditation for quality service. We
                  are ISO 9001:2015 Certified Firm. In 2014, we have been rated
                  29th leading, prominent and upcoming Chartered Accountants
                  Firm of India and 8th in Delhi by a leading monthly magazine,
                  ‘Bureaucracy Today’.
                </p>
                <p>
                  We have been Peer reviewed by Institute of Chartered
                  Accountants of India. Peer Review Certification is required
                  for conducting Audit of Listed Companies in India.{" "}
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 progress-content-img p-md-2 p-3 wow fadeInLeft"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/Quality-Accreditation-.jpg"
                alt=""
                className="img-fluid image1"
              />
            </div>
          </div>

          <div className="row borders">
            <div
              className="col-lg-8 p-md-3 p-3 progress-content text-left  wow fadeInRight"
              data-wow-duration="1s"
            >
              <h3 className="title-w3pvt2 mb-4">Global Connectivity </h3>
              <p>
                We have 24 hrs Internet Lease Line access with a bandwidth of
                more than 20 mbps having Internet connection in all Desktops &
                Laptops.
              </p>

              <p>
                Individual e-mail ID's are provided to each employee for proper
                communication. Further, we have general e-mail ID’s for query
                handling.
              </p>
            </div>
            <div
              className="col-lg-4 progress-content-img p-md-2 p-3 wow fadeInLeft"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/global-connectivity.jpg"
                alt=""
                className="img-fluid image1"
              />
            </div>
          </div>

          <div className="row borders">
            <div
              className="col-lg-8 p-md-3 p-3 progress-content text-left  wow fadeInRight"
              data-wow-duration="1s"
            >
              <h3 className="title-w3pvt2 mb-3">Online Presence </h3>
              <p>
                We have our online presence with website:{" "}
                <a href="https://ca-web1.web.app/">https://ca-web1.web.app/ </a>
              </p>

              <h3 className=" title-w3pvt2 mb-1">
                Professional Indemnity Insurance:{" "}
              </h3>
              <p>
                We have obtained Professional Indemnity Insurance Policy from
                Raheja QBE General Insurance Company Limited.
              </p>

              <h3 className="title-w3pvt2 mb-1">Online Resource: </h3>
              <p>
                {" "}
                We have website{" "}
                <a href="https://ca-web1.web.app/" target="_blank">
                  https://ca-web1.web.app/
                </a>{" "}
                for Resources on Business Laws in India.{" "}
              </p>
            </div>
            <div
              className="col-lg-4 progress-content-img p-md-2 p-3 wow fadeInLeft"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/online-presence.jpg"
                alt=""
                className="img-fluid image1"
              />
            </div>
          </div>

          <div className="row borders">
            <div
              className="col-lg-4 progress-content-img p-md-2 p-3 wow fadeInLeft"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/toll-free.jpg"
                alt=""
                className="img-fluid image1"
              />
            </div>
            <div
              className="col-lg-8 p-md-3 p-3 progress-content text-left  wow fadeInRight"
              data-wow-duration="1s"
            >
              <h3 className="title-w3pvt2 mb-4">Toll Free Telephone Number </h3>
              <p>
                To serve our Clients in a better way, we have Toll Free All
                India Telephone Number 1800110099. Our Clients can call us from
                their local phone from any where in India free of any charges.
                They are not required to prefix “0” or “011” on this number.
              </p>
            </div>
          </div>

          <div className="row borders">
            <div
              className="col-lg-8 p-md-3 p-3 progress-content text-left  wow fadeInRight"
              data-wow-duration="1s"
            >
              <h3 className="title-w3pvt2 mb-3">IT Infrastructure </h3>
              <p>Window Server 2016 is installed on main Server.</p>

              <p>
                Cyberoam Fire wall is installed on the network and Symantec
                Antivirus software is installed on server and computers for data
                safety and security.
              </p>
            </div>
            <div
              className="col-lg-4 progress-content-img p-md-2 p-3 wow fadeInLeft"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/it-Infrastructure.jpg"
                alt=""
                className="img-fluid image1"
              />
            </div>
          </div>

          <div className="row borders">
            <div
              className="col-lg-4 progress-content-img p-md-2 p-3 wow fadeInLeft"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/departments.jpg"
                alt=""
                className="img-fluid image1"
              />
            </div>
            <div
              className="col-lg-8 p-md-3 p-3 service-call progress-content text-left  wow fadeInRight"
              data-wow-duration="1s"
            >
              <h3 className="title-w3pvt2 mb-3">
                We have following Departments for smooth functioning{" "}
              </h3>
              <ul>
                <li>Accounts Department</li>
                <li>Audit Department </li>
                <li>Income-tax Department </li>
                <li>Corporate Law Department </li>
                <li>Finance Department </li>
                <li>Goods & Service Tax (GST) Department</li>
                <li>‘Investment in India’ & ‘Start-up’ Department</li>
              </ul>
            </div>
          </div>

          <div className="row borders">
            <div
              className="col-lg-8 p-md-3 p-3 service-call progress-content text-left  wow fadeInRight"
              data-wow-duration="1s"
            >
              <h3 className="title-w3pvt2 mb-3">
                Following categories of person form integral part of the Team of
                C.A.Web & Co{" "}
              </h3>
              <ul>
                <li>Chartered Accountants</li>
                <li>Company Secretaries</li>
                <li>Cost & Management Accountants </li>
                <li>Articled Clerks</li>
                <li>Commerce Graduates & Post graduates</li>
                <li>MBAs</li>
                <li>LLBs</li>
                <li>Retired IAS, IPS, IRS Officers of Government of India</li>
                <li>Retired Members of Income Tax Appellate Tribunal (ITAT)</li>
              </ul>
            </div>
            <div
              className="col-lg-4 progress-content-img p-md-2 p-3 wow fadeInLeft"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/integral-part.jpg"
                alt=""
                className="img-fluid image1"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Infrastructure;
