import React from "react";
import NavHeader from "../../../components/NavHeader";

const Auditing = () => {
  return (
    <div>
      <NavHeader name={"Auditing & Assurance"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/Untitled-2.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Auditing & Assurance Services</h3>
                <p>
                  With businesses seeking greater success on a global scale, it
                  is becoming more and more important to set the right
                  regulatory practices in auditing and assurance. This is where
                  we come into the picture and assist you in meeting these
                  demands with a thorough auditing and seamless assurance. We
                  not just provide great services for you and your organization,
                  but also give way to clear and transparent information, which
                  is beneficial for your stakeholders and investors.
                </p>

                <ul>
                  <li>Statutory Audit</li>
                  <li>Internal Audit</li>
                  <li>
                    Internal Financial Control (IFC) over financial reporting
                    Audit
                  </li>
                  <li>Bank Concurrent Audit</li>
                  <li>Bank Statutory Audit</li>
                  <li>Income Tax Audit u/s 44 AB</li>
                  <li>Transfer Pricing Audit u/s 92 E</li>
                  <li>GST Audit </li>
                  <li>Form 10B Audit in case of NGO</li>
                  <li>Forensic Audit</li>
                  <li>Due diligence</li>
                  <li>Certification work</li>
                  <li>
                    Audit of Form 15CB of Income Tax Act and Issue Form 15CA
                  </li>
                  <li>
                    SOX Compliance to ensure adequate internal controls are in
                    place and effective
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Auditing;
