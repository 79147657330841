export const servicesData = [
  {
    id: 1,
    title: "Accounting",
    icon: "https://gkkediaandco.com/wp-content/themes/gkkedia/images/account-icon-color.png",
    url: "/services/accounting/",
    description:
      "The history of accounting goes back a 1000 years, but the relevance of it still stays strong...",
  },
  {
    id: 2,
    title: "AUDITING & ASSURANCE",
    icon: "https://gkkediaandco.com/wp-content/themes/gkkedia/images/audting-icon-color.png",
    url: "/services/auditing-assurance/",
    description:
      " With businesses seeking greater success on a global scale, it is becoming more and more...",
  },
  {
    id: 3,
    title: "Income Tax",
    icon: "https://gkkediaandco.com/wp-content/themes/gkkedia/images/tex-icon-blu.png",
    url: "/services/income-tax/",
    description:
      "The tax landscapes have changed a great deal over the years, and the complexity of change...",
  },
];

export const ServicesData = servicesData.map((item) => {
  return (
    <li
      key={item.id}
      className="service-block-two col-sm-4 col-lg-4 col-md-6  text-center "
    >
      <div className="inner-box">
        <div className="icon-box ">
          <img src={item.icon} alt="" className="color" />
          <img
            src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/account-icon-wh.png"
            alt=""
            className="wh"
          />
        </div>
        <h5>
          <a
            href={item.url}
            // style={{
            //   textDecoration: "none",
            //   color: "black",
            //   textTransform: "uppercase",
            // }}
          >
            {item.title}
          </a>
        </h5>
        <div className="text">{item.description}</div>
      </div>
    </li>
  );
});

export const servicesData2 = [
  {
    id: 1,
    title: "Goods & Services Tax (GST)",
    icon: "https://gkkediaandco.com/wp-content/themes/gkkedia/images/gst-icon-color.png",
    url: "/services/goods-services-tax-gst/",
    description:
      "The GST journey in India began in the year 2000, and since then, has come a long way in shaping...",
  },
  {
    id: 2,
    title: "Company Law Matters Consultancy",
    icon: "https://gkkediaandco.com/wp-content/themes/gkkedia/images/low-iocn-color.png",
    url: "/services/company-law-matters-consultancy/",
    description:
      "Whether it is the formation of Indian or offshore organizations, clients require thorough...",
  },
  {
    id: 3,
    title: "Startup Services",
    icon: "https://gkkediaandco.com/wp-content/themes/gkkedia/images/startup-icon-color.png",
    url: "/services/startup-services/",
    description:
      "Starting your own venture takes a lot of confidence and grit, and we are here to back you up with...",
  },
];

export const ServicesData2 = servicesData2.map((item) => {
  return (
    <li
      key={item.id}
      className="service-block-two col-sm-4 col-lg-4 col-md-6  text-center "
    >
      <div className="inner-box">
        <div className="icon-box ">
          <img src={item.icon} alt="" className="color" />
          <img
            src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/account-icon-wh.png"
            alt=""
            className="wh"
          />
        </div>
        <h5>
          <a
            href={item.url}
            // style={{
            //   textDecoration: "none",
            //   color: "black",
            //   textTransform: "uppercase",
            // }}
          >
            {item.title}
          </a>
        </h5>
        <div className="text">{item.description}</div>
      </div>
    </li>
  );
});

export const ourService = [
  {
    id: 1,
    title: "Auditing & Assurance",
    url: "/services/income-tax/",
  },
  {
    id: 2,
    title: "Income Tax",
    url: "",
  },
  {
    id: 3,
    title: "Goods & Services Tax (GST)",
    url: "",
  },
  {
    id: 4,
    title: "Accounting",
    url: "",
  },
  {
    id: 5,
    title: "Company Law Matters Consultancy",
    url: "",
  },
  {
    id: 6,
    title: "Startup Services",
    url: "",
  },
  {
    id: 7,
    title: "Societies and Trust (NGO)",
    url: "",
  },
  {
    id: 8,
    title: "Finance",
    url: "",
  },
  {
    id: 9,
    title: "Business Process Outsources (BPO)",
    url: "",
  },
  {
    id: 10,
    title: "CA Certification",
    url: "",
  },
  // {
  //   id: 11,
  //   title: "Business Process Outsources (BPO)",
  //   url: "",
  // },
];

export const Servicesdata = [
  {
    id: 1,
    title: "Auditing & Assurance",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2021/07/M-A-Merger-1.png",
    url: "/services/auditing-assurance/",
    desc: "With businesses seeking greater success on a global scale, it is becoming more and more important to set the right regulatory practices in auditing and assurance. This is where we come into the picture and assist you in meeting these demands with a thorough auditing and seamless assurance. We not just provide great services for you and your organization, but also give way to clear and transparent information, which is beneficial for your stakeholders and investors.",
    description: "Statutory Audit",
    desc1: "Internal Audit",
    desc2: "Internal Financial Control (IFC) over financial reporting Audit",
    desc3: "Bank Concurrent Audit",
    desc4: "Bank Statutory Audit",
    desc5: "Income Tax Audit u/s 44 AB",
    desc6: "Transfer Pricing Audit u/s 92 E",
    desc7: "GST Audit",
    desc8: "Form 10B Audit in case of NGO",
    desc9: "Forensic Audit",
    desc10: "Due diligence",
    desc11: "Certification work",
    desc12: "Audit of Form 15CB of Income Tax Act and Issue Form 15CA",
    desc13:
      " SOX Compliance to ensure adequate internal controls are in place and effective",
  },
  {
    id: 2,
    title: "Income Tax",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/Income-tax.png",
    url: "/services/income-tax/",
    desc: "The tax landscapes have changed a great deal over the years, and the complexity of change in the tax scenario continues to grow at a fast pace. This change in the corporate and private space has brought about a greater shift in the way Tax authorities work, and therefore it is necessary to have the right procedures in place when it comes to taxes.",
    description: "Preparation of Income Tax Return",
    desc1: "Rectification of Proposed Adjustments in Income Tax Returns",
    desc2:
      "Faceless Assessment (Scrutiny) of Tax Returns and Penalty Proceedings",
    desc3: "Consultancy in Income Tax matters & Tax Planning",
    desc4: "Specified Financial Transactions (Form 61A, Form 61B)",
    desc5: "Income Tax Audit u/s 44 AB",
    desc6: "Form 10B Audit in case of NGO, 10BB Audit",
    desc7: "Ex-patriate Taxation",
    desc8:
      "Tax Evasion Petitions, CPGRAMS, raising grievances for long pending matters",
    desc9: "Assistance in Equilization Levy Compliances",
    desc10: "Preparation and representation before authorities",
    desc11:
      "Representation before authorities & Compounding of Offences in TDS",
    desc12: "Quarterly Statement in Form15CC (Rule 37BB)",
    desc13: " International taxation consultancy",
    desc14:
      "Transfer Pricing matters-TP Study, TP Audit, TP Assessment & Objections with DRP",
    desc15: "TDS & Withholding tax compliance",
    desc16: "Lower or No TDS Deduction Certificate related consultancy",
    desc17:
      "Income tax Faceless Appeals before Commissioner of Income Tax (Appeals)",
    desc18: "Representation before Dispute Resolution Committee",
    desc19: " Income tax Appeals before Income Tax Appellate Tribunal (ITAT)",
    desc20: "Survey, Search & Seizer Consultancy",
  },
  {
    id: 3,
    title: "Goods & Services Tax (GST)",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/gst.png",
    url: "/services/goods-services-tax-gst/",
    desc: "The GST journey in India began in the year 2017, and since then, it has come a long way in shaping our economy. All matters like registration, form filing, application for refund, etc. are taken care of by our skilled and efficient Chartered Accountants . It is our sole purpose and duty to take part in and to give way to a robust and open discussion surrounding accountable GST, increased transparency and the ensuring of taxation in an increasingly digitized and globalized world.",
    description: "Filing of monthly, quarterly and Annual GST returns",
    desc1: " Assistance in deposit of GST to electronic cash ledger",
    desc2: "Consultancy on GST on regular basis",
    desc3: "Refund of GST from GST Department",
    desc4: " Bond and Letter of Undertaking (LUT) related work for Exporters",
    desc5: "Assisting in Internal Audit by GST Department",
    desc6:
      " Assisting and Representation in Show Cause Notice issued by Superintendent, GST Audit Department",
    desc7: "Search Cases under GST",
    desc8: "Contract review from GST perspective",
    desc9:
      "Representation before relevant authority for GST appeal i.e. Commissioner, CESTAT",
    desc10: "",
    desc11: "",
    desc12: "",
    desc13: " ",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    desc18: "",
    desc19: "",
    desc20: "",
  },
  {
    id: 4,
    title: "Accounting",
    image: "	https://gkkediaandco.com/wp-content/uploads/2019/06/ac.png",
    url: "/services/accounting/",
    desc: "The history of accounting goes back a 1000 years, but the relevance of it still stays strong, today! Keeping up with the multiple aspects of a business can be difficult, and that is why we are here to offer bookkeeping, succession planning and strategic planning, so that you can focus on other important matters and not worry about the financial accounting of your organization.",
    description:
      "Book Keeping and Accounting for Individuals, Firms, NGOs and Companies including foreign Companies",
    desc1: " Design, implementation and review of accounting manual",
    desc2: " Advice on various Accounting Standards, Ind AS & IFRS",
    desc3: "",
    desc4: " ",
    desc5: "",
    desc6: "",
    desc7: " ",
    desc9: "",
    desc10: "",
    desc11: "",
    desc12: "",
    desc13: "",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    desc18: "",
    desc19: "",
    desc20: "",
  },
  {
    id: 5,
    title: "Company Law Matters Consultancy",
    image:
      "	https://gkkediaandco.com/wp-content/uploads/2019/06/Company-Law-Matters-Consultancy.png",
    url: "/services/company-law-matters-consultancy/",
    desc: "Whether it is the formation of Indian or offshore organizations, clients require thorough assistance with several aspects related to business. Strategic planning is as important as risk analysis and future profitability and therefore we provide seamless services in Company Law matters that can help you pave the way to a successful and lucrative tomorrow!",
    description:
      " Formation of company in India, viz. Private Limited, Public Limited, wholly owned subsidiary company, One Person Company (OPC), Limited Liability Partnership (LLP), etc.",
    desc1:
      "All matters related with Registrar of Companies (ROC), Regional Director (RD) & National Company Law Tribunal (NCLT)",
    desc2: "Maintenance & compliance of Statutory Records",
    desc3: "",
    desc4: "",
    desc5: "Valuation of Equity Shares",
    desc6: "XBRL filings",
    desc7: "Consultancy in above matters",
    desc8: "",
    desc9: "",
    desc10: "",
    desc11: "",
    desc12: "",
    desc13: "",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    desc18: "",
    desc19: "",
    desc20: "",
  },
  {
    id: 6,
    title: "Startup Services",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Startup-Services.png",
    url: "/services/startup-services/",
    desc: "Startup Services in India takes a lot of confidence and grit and we are here to back you up with the right financial advice and road map. Your ultimate goal while opening any startup is to reap maximum profit and that is what our services help you achieve. Time is money and no one understands that better than us! Get started on the right path with your startup and let us fuel your dreams and aspirations with the best of financial services.",
    description: " Assistance in identifying the right business structure",
    desc1: "Obtaining Business licenses",
    desc2: "Incorporation of Company and LLP (Limited Liability Partnership)",
    desc3: "Setting up Policies and Procedures",
    desc4: "",
    desc5: "",
    desc6: "",
    desc7: "",
    desc8: "",
    desc9: "",
    desc10: "",
    desc11: "",
    desc12: "",
    desc13: " ",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    desc18: "",
    desc19: "",
    desc20: "",
  },
  {
    id: 7,
    title: "Societies and Trust (NGO)",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Societies-and-Trust-NGO.png",
    url: "/services/societies-and-trust-ngo/",
    desc: "Charity begins at home and has countless ripples that affect the societies and communities at large. Whether it is a trust managed by a few people or a large section of the society, our services provide the best financial advice and services. Trust and societies form a pivotal pillar of the economic structure of the country, hence it is our duty to ensure that your organization stands on a strong and sustainable economic pillar.",
    description: "Formation of Society, Trust & Section 8 Company",
    desc1:
      " Fresh Registration U/s 12A/ 12AA/ 12AB (Form 10AC) and U/s 80G (Form 10AC) of Income Tax Act",
    desc2:
      "Provisional Registration U/s 12AB (Form 10AC) and U/s 80G (Form 10AC) of Income Tax Act",
    desc3: "Registration under Foreign Contribution Regulation Act (FCRA)",
    desc4:
      " Filing of Returns under Foreign Contribution Regulation Act (FCRA)",
    desc5: "Issue of certificate of compliance of CSR Funds",
    desc6: "Assisting NGOs in filing Form 10BD – Statement of donations",
    desc7:
      " Assisting NGOs in issuing Form 10BE – Certificate of donation to the donors",
    desc8: "Compliances with ROC for CSR Activities",
    desc9: "Compliances in NGO Darpan NITI Aayog",
    desc10: "Donor Audit of NGO",
    desc11: "Investigative Audit of NGO",
    desc12: "Consultancy in above matters",
    desc13: "",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    desc18: "",
    desc19: "",
    desc20: "",
  },
  {
    id: 8,
    title: "Finance",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/Finance.png",
    url: "/services/finance/",
    desc: "At C. A. Web & Co. we focus on the future of finance, the evolving market trends, and how this goes into shaping every venture, big or small. Working with clients, giving a uniformed structure to their business goals and helping them execute their plans in an effective way is what sets us apart from other Chartered Accountant Firms. Whether it’s data, technology or analytics, we do it all with a quick turnaround time.",
    description: "Preparation of project report",
    desc1: "Preparation of CMA Data",
    desc2: "Loan arrangement- Cash credit limit, Term loan & Project loan",
    desc3: "Due diligence for finance",
    desc4: "Valuation of Business",
    desc5: "",
    desc6: "",
    desc7: "",
    desc8: "",
    desc9: "",
    desc10: "",
    desc11: "",
    desc12: "",
    desc13: "",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    desc18: "",
    desc19: "",
    desc20: "",
  },
  {
    id: 9,
    title: "Business Process Outsources (BPO)",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/bpo.png",
    url: "/services/business-process-outsource-bpo/",
    desc: "Outsourcing has gone through so many reforms in the last couple of decades and has given way to a whole new economic landscape in India. At C. A. Web & Co., we help our clients and empower their outsourcing businesses with new age digital tools and processes that help them move forward in accomplishing their goals. We believe in modernizing the shared service operations through robust budgeting, management reporting, and treasury. Get in touch with us Now, to see how we can help you with your BPO venture and business.",
    description:
      "Book-keeping for clients located at USA, UK, Canada, Australia & other countries",
    desc1: "Pay roll processing",
    desc2: "Bank reconciliation statement",
    desc3: "Accounts Receivables and Payables reconciliation",
    desc4: "HR Services",
    desc5:
      " Preparation and Review of US Tax Returns i.e FORM 1040 , 1040NR ,1065 ,1120 , 1120S etc.",
    desc6: "",
    desc7: "",
    desc8: "",
    desc9: "",
    desc10: "",
    desc11: "",
    desc12: "",
    desc13: "",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    desc18: "",
    desc19: "",
    desc20: "",
  },
  {
    id: 10,
    title: "CA Certification",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/ac.png",
    url: "/services/ca-certification/",
    desc: "",
    description:
      "Certification of Networth for Visa Processing,Bank Finances, Bank Guarantee",
    desc1: "Certification of Turnover of Business",
    desc2: "Certification for RERA Purpose",
    desc3:
      " Certification of Funds Utilisation of Funds or Grant under Charitiable Trust or CSR Activities",
    desc4: "Certifiation of Working Capital",
    desc5:
      " Certification issues for KYC purpose to Banks confirming sole proprietorship",
    desc6: "",
    desc7: "",
    desc8: "",
    desc9: "",
    desc10: "",
    desc11: "",
    desc12: "",
    desc13: "",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    desc18: "",
    desc19: "",
    desc20: "",
  },
];

export const latestBlogs = [
  {
    id: 1,
    title: "RECENT CHANGES IN REPO RATE",
    image: "https://gkkediaandco.com/wp-content/uploads/2023/02/1.jpg",
    date: "February 7th, 2023",
    url: "/blogs/",
    description:
      "Repo rate – The repo rate is the rate at which the Reserve Bank of India (RBI) lends money to",
  },
  {
    id: 2,
    title: "Highlights of Union Budget 2023-24.",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2023/02/HIGHLIGHTS-OF-UNION-BUDGET.jpeg",
    date: "February 27th, 2023",
    url: "/blogs/",
    description:
      " ➢ General key Highlights of Union Budget 2023 1. EPFO Numbers doubled to 27 crores 2. Agriculture accelerator fund to",
  },
  {
    id: 3,
    title: "INCOME TAX REFUND",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2023/01/gkkedia-blog-image.jpg",
    date: "January 13th, 2023",
    url: "/blogs/",
    description:
      " Basics The Term Income tax refers to the type of tax that the government imposed on income earned by the",
  },
];

export const ourTeam = [
  {
    id: 1,
    title: "Founder",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/FOUNDER-pARTNER-cions.png",
    description: "CA Dhaval is the Managing Partner of the Firm.",
    url: "/founder-partner/",
  },
  {
    id: 2,
    title: "Professional Consultant",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/PARTNER-2.png",
    description:
      "Our exceptionally talented team that works hard for its clients",
    url: "/partners/",
  },
  {
    id: 3,
    title: "Business Consultant",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/charted-accountaent-icon.png",
    description: "Meet our skillful and expert Business Consultants",
    url: "/business-consultant/",
  },
  {
    id: 4,
    title: "Key Staff",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/key-staff-icons.png",
    description:
      "Here is the list of our exponentially valued key staff members.",
    url: "/key-staff/",
  },
];

export const CountryList = [
  { name: "Albania", code: "AL" },
  { name: "Åland Islands", code: "AX" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas (the)", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia (Plurinational State of)", code: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory (the)", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cayman Islands (the)", code: "KY" },
  { name: "Central African Republic (the)", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands (the)", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros (the)", code: "KM" },
  { name: "Congo (the Democratic Republic of the)", code: "CD" },
  { name: "Congo (the)", code: "CG" },
  { name: "Cook Islands (the)", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic (the)", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (the) [Malvinas]", code: "FK" },
  { name: "Faroe Islands (the)", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories (the)", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia (the)", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and McDonald Islands", code: "HM" },
  { name: "Holy See (the)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran (Islamic Republic of)", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea (the Democratic People's Republic of)", code: "KP" },
  { name: "Korea (the Republic of)", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic (the)", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands (the)", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia (Federated States of)", code: "FM" },
  { name: "Moldova (the Republic of)", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands (the)", code: "NL" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger (the)", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands (the)", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine, State of", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines (the)", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Republic of North Macedonia", code: "MK" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation (the)", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Réunion", code: "RE" },
  { name: "Saint Barthélemy", code: "BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin (French part)", code: "MF" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten (Dutch part)", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan (the)", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan (Province of China)", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands (the)", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates (the)", code: "AE" },
  {
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    code: "GB",
  },
  { name: "United States Minor Outlying Islands (the)", code: "UM" },
  { name: "United States of America (the)", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands (British)", code: "VG" },
  { name: "Virgin Islands (U.S.)", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const Partners = [
  {
    id: 1,
    name: "CA NIKHIL JOSHI",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2022/05/ca-ankit-1-1.jpg",
    qualification: "M.Com, ACA",
    MNo: "604845",
    post: "Operation Incharge of Income Tax, Audit, GST",
    mobile: "7575858594",
    email: "nikhiljoshi501@gmail.com",
    description:
      "CA NIKHIL JOSHI is Joint Partner and Head of Income Tax Department and GST in the Firm. She qualified as a Chartered Accountant in May 2012 and has an experience of 10 years in the field of Direct Taxation. Her area of specialized expertise is Litigation in Direct Taxation and also gathered an immense experience in the field of other Income Tax Matters such as DTAA consultancy, Transfer Pricing Matters, Statutory Audit, Concurrent Audit, Bank Audit, Internal Audit, Tax Audit, FEMA, Accounting Standard, Service tax, GST etc.  She has handled a large number of Tax appeals of leading Companies and Organizations with Assessing Officer, Dispute Resolution Panel (DRP), CIT (Appeal), ITAT, CESTAT etc. She is having experience in the field of Transfer Pricing Matters, Tax Audit, FCRA, GST, GST Audit, Service tax, etc. She is also expert in reviewing and handling tax issues in International Taxation, Repatriation of Funds, Legacy Dispute Resolution Scheme, Vivad Se Vishwas Scheme etc.",
  },
  // {
  //   id: 2,
  //   name: "CA MUDITA SAMIYA",
  //   image:
  //     "https://gkkediaandco.com/wp-content/uploads/2021/07/CA-Rachna-Gupta.jpg",
  //   qualification: "B.Com (Hons.), FCA",
  //   MNo: "501754",
  //   post: "Assistant Partner – Audit",
  //   mobile: "9821694224",
  //   email: "mudita@caweb.com",
  //   description:
  //     " CA MUDITA SAMIYA is the Assistant Partner of the Firm. She qualified as a CA in the year 2002. She is a Fellow member of The Institute of Chartered Accountants of India. She has 20 years of experience in the field of Accountancy, Taxation, Company Law Matters, Foreign Taxation, Service Tax, FCRA, etc. She has exemplary experience in handling matters relating to Trusts and NGOs including Registration, Compliance Monitoring, FCRA Regulations etc. She brings in a progressive & an enthusiastic approach to client service.",
  // },
  {
    id: 3,
    name: "CS BRINDA SONI",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Tanu-Bhasin-removebg-preview.png",
    qualification: "B.Com, Company Secretary",
    MNo: "502066",
    post: "Head of Management Department",
    mobile: "8826897211",
    email: "brinda@caweb.com",
    description:
      "CS BRINDA SONI is the Executive Partner of the Firm. She qualified as a CA in 2002. She is a Fellow member of Institute of Chartered Accountants of India & has over 20 years experience in the field of Tax Audit, Statutory Audit, Accounting, Company Law Matters, IFRS and Income Tax Matters.",
  },
  // {
  //   id: 4,
  //   name: "CA Dinesh Kedia",
  //   image:
  //     "	https://gkkediaandco.com/wp-content/uploads/2022/05/ca-ankit-1-1.jpg",
  //   qualification: "B.Com (Hons.), FCA",
  //   MNo: "070883",
  //   post: "Executive Partner & Finance",
  //   mobile: "9512334564",
  //   email: "dinesh@caweb.com",
  //   description:
  //     "CA Dinesh is the Executive Partner of the Firm. He qualified as a CA in the year 1981 and is a Fellow member of Institute of Chartered Accountants of India. He provides an insightful audit to all the clients beyond the conventional assessment of numbers & has 41 years of experience in the field of Statutory Audit, Internal Audit, FEMA, Income Tax, Service Tax, Company Law Matters, Financial Services that are tailored to suit the specifications of each organization.",
  // },
  // {
  //   id: 5,
  //   name: "CA Vishnu Kumar Sultania",
  //   image:
  //     "https://gkkediaandco.com/wp-content/uploads/2021/07/CA-Vishnu-Kumar-Sultania.jpg",
  //   qualification: "B.Com, FCA, CS",
  //   MNo: "056476",
  //   post: "Executive Partner",
  //   mobile: "9810025049",
  //   email: "vishnu@caweb.com",
  //   description:
  //     "CA Vishnu Kumar Sultania is the Executive Partner of the Firm. He is a qualified Chartered Accountant in May 1993 and a Company Secretary. He has 29 years of experience in the field of cross border Mergers and Acquisitions (M&A), Infrastructure Space, Fund-Raising for technology companies, Security and Facility Management market. He has been working with founders of Start-ups and Promoters of High growth companies in their Inorganic Initiatives. He brings in a unique combination of Finance, Value addition and an ability to close transactions in a time bound manner. He is also an award winning professional and ranked among the best 100 CFOs in India with a clear win in the Merger and Acquisition front.",
  // },
];

export const CharteredAccountants = [
  {
    id: 1,
    name: "Mamta Nigam",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/mamta-nigam-1-removebg-preview-1.png",
    qualification: "B. Com. (Hons.), IPA",
    MNo: "528290",
    post: "Australia Branch Incharge",
    mobile: "+61 423125079",
    email: "mamta@caweb.com",
    description:
      "Mamta Nigam is a member of Institute of Public Accountant, Australia. She is an Australian citizen. She is Incharge of our Australia office and handles all Accounting, Auditing, Taxation and consultancy services in Australia for the clients locally located or from outside Australia.",
  },
  {
    id: 2,
    name: "CA Sandeep Saini",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/2-1-1.jpg",
    qualification: "B.Com., ACA ",
    MNo: "530193",
    post: "Assistant Manager – Income Tax",
    mobile: "9821694224",
    email: "sandeep.saini@caweb.com",
    description:
      "CA Sandeep Saini has done B.Com from Delhi University.  She has qualified as a CA in May, 2017. She has 5 years of experience in the field of Accounting, Statutory and Internal Audit compliance etc.",
  },
];

export const keyStaff = [
  {
    id: 1,
    name: "HITESH JADAV",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2022/05/ca-ankit-1-1.jpg",
    qualification: "B. Com. (Hons.), MBA",

    post: "Head of Accounts Department",
    mobile: "+91 992",
    email: "hitesh@caweb.com",
    description:
      "Hitesh Jadav is a member of Institute of Public Accountant, Australia. She is an Australian citizen. She is Incharge of our Australia office and handles all Accounting, Auditing, Taxation and consultancy services in Australia for the clients locally located or from outside Australia.",
  },
  {
    id: 2,
    name: "RUTVI PATHAK",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/mamta-nigam-1-removebg-preview-1.png",
    qualification: "M.Com.",
    post: "Account Staff",
    mobile: "+61 423125079",
    email: "rutvi@caweb.com",
    description:
      "Rutvi Pathak is a member of Institute of Public Accountant, Australia. She is an Australian citizen. She is Incharge of our Australia office and handles all Accounting, Auditing, Taxation and consultancy services in Australia for the clients locally located or from outside Australia.",
  },
  {
    id: 3,
    name: "RAHUL PANDYA",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2022/05/ca-ankit-1-1.jpg",
    qualification: "B.Com., MBA, LLB",
    post: "Head of Income Tax Department",
    mobile: "9821694224",
    email: "rahul@caweb.com",
    description:
      "Rahul Pandya has done B.Com from Delhi University.  She has qualified as a CA in May, 2017. She has 5 years of experience in the field of Accounting, Statutory and Internal Audit compliance etc.",
  },
  {
    id: 4,
    name: "YAMINI JADEJA",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/2-1-1.jpg",
    qualification: "M.Com. ",
    post: "Income Tax Staff",
    mobile: "9821694224",
    email: "yamini@caweb.com",
    description:
      "Yamini Jadeja has done B.Com from Delhi University.  She has qualified as a CA in May, 2017. She has 5 years of experience in the field of Accounting, Statutory and Internal Audit compliance etc.",
  },
  {
    id: 5,
    name: "SAGAR TRIVEDI",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2022/05/ca-ankit-1-1.jpg",
    qualification: "M.Com. ",
    post: "Head of GST Department",
    mobile: "9821694224",
    email: "sagar@caweb.com",
    description:
      "Sagar Trivedi has done B.Com from Delhi University.  She has qualified as a CA in May, 2017. She has 5 years of experience in the field of Accounting, Statutory and Internal Audit compliance etc.",
  },
  {
    id: 6,
    name: " AARTI BARIA",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/2-1-1.jpg",
    qualification: "B.Com. ",
    post: "GST Staff",
    mobile: "9821694224",
    email: "aarti@caweb.com",
    description:
      "Aarti Baria has done B.Com from Delhi University.  She has qualified as a CA in May, 2017. She has 5 years of experience in the field of Accounting, Statutory and Internal Audit compliance etc.",
  },
  // {
  //   id: 5,
  //   name: "RUPA RAI",
  //   image:
  //     "https://gkkediaandco.com/wp-content/uploads/2019/06/mamta-nigam-1-removebg-preview-1.png",
  //   qualification: "B. Com. (Hons.), IPA",
  //   post: "Australia Branch Incharge",
  //   mobile: "+61 423125079",
  //   email: "rupa@caweb.com",
  //   description:
  //     "Rupa Rai is a member of Institute of Public Accountant, Australia. She is an Australian citizen. She is Incharge of our Australia office and handles all Accounting, Auditing, Taxation and consultancy services in Australia for the clients locally located or from outside Australia.",
  // },

  {
    id: 7,
    name: "NIKUNJ GOHIL",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2022/05/ca-ankit-1-1.jpg",
    qualification: "BCA, M.Sc(IT)",
    post: "Investment Advisor",
    mobile: "+61 423125079",
    email: "nikunj@caweb.com",
    description:
      "Nikunj Gohil is a member of Institute of Public Accountant, Australia. She is an Australian citizen. She is Incharge of our Australia office and handles all Accounting, Auditing, Taxation and consultancy services in Australia for the clients locally located or from outside Australia.",
  },

  {
    id: 9,
    name: "MEET PANDYA",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2022/05/ca-ankit-1-1.jpg",
    qualification: "B. Com., M.Com",
    post: "Investment Advisor in Real Estate",
    mobile: "+61 423125079",
    email: "meet@caweb.com",
    description:
      "Meet Pandya is a member of Institute of Public Accountant, Australia. She is an Australian citizen. She is Incharge of our Australia office and handles all Accounting, Auditing, Taxation and consultancy services in Australia for the clients locally located or from outside Australia.",
  },
];

export const businessConsultant = [
  {
    id: 1,
    name: "HAFIZKHAN AJAMKHAN KURESHI (FINANCE)",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2022/05/ca-ankit-1-1.jpg",
    qualification: "B. Com. (Hons.), IPA",
    MNo: "528290",
    post: "Business Consultant - Loans And Finance Consultant",
    mobile: "+61 423125079",
    email: "hitesh@caweb.com",
    description:
      "Hitesh Jadav is a member of Institute of Public Accountant, Australia. She is an Australian citizen. She is Incharge of our Australia office and handles all Accounting, Auditing, Taxation and consultancy services in Australia for the clients locally located or from outside Australia.",
  },
  {
    id: 2,
    name: "SUNIL TRIVEDI (USA)",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/2-1-1.jpg",
    qualification: "B.Com. ",
    MNo: "530193",
    post: "Business Consultant - US Accounts And Tax Department",
    mobile: "9821694224",
    email: "yamini@caweb.com",
    description:
      "Yamini Jadeja has done B.Com from Delhi University.  She has qualified as a CA in May, 2017. She has 5 years of experience in the field of Accounting, Statutory and Internal Audit compliance etc.",
  },
  {
    id: 3,
    name: "RIDHAM MAVANI",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/mamta-nigam-1-removebg-preview-1.png",
    qualification: "M.Com., IPA",
    MNo: "528290",
    post: "Business Consultant - US Accounts And Tax Department",
    mobile: "+61 423125079",
    email: "rutvi@caweb.com",
    description:
      "Rutvi Pathak is a member of Institute of Public Accountant, Australia. She is an Australian citizen. She is Incharge of our Australia office and handles all Accounting, Auditing, Taxation and consultancy services in Australia for the clients locally located or from outside Australia.",
  },
];

export const ImportantLinks = [
  {
    id: 1,
    name: "Insolvency and Bankruptcy Board of India",
    image: "https://gkkediaandco.com/wp-content/uploads/2021/03/logo.jpg",
    url: "https://ibbi.gov.in/",
  },
  {
    id: 2,
    name: "Central Board Of Indirect Tax And Customs",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Central-Board-Of-Indirect-Tax-And-Customs.jpg",
    url: "http://www.cbic.gov.in/",
  },
  {
    id: 3,
    name: "Director General of Foreign Trade",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Director-General-of-Foreign-Trade.jpg ",
    url: "http://dgft.gov.in/",
  },
  {
    id: 4,
    name: "Foreign Contribution Regulation Act",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Foreign-Contribution-Regulation-Act.jpg ",
    url: "https://fcraonline.nic.in/home/index.aspx",
  },
  {
    id: 5,
    name: "Foreign Direct Investment",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/foreign-img.png",
    url: "https://www.fifp.gov.in/",
  },
  {
    id: 6,
    name: "Foreign Exchange Management Act",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/rbi.jpg",
    url: "https://rbi.org.in/scripts/fema.aspx",
  },
  {
    id: 7,
    name: "Goods and Services Tax Department",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/gst.jpg",
    url: "https://www.gst.gov.in/",
  },
  {
    id: 8,
    name: "Income Tax Appellate Tribunal",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Income-Tax-Appellate-Tribunal.jpg",
    url: "https://www.itat.gov.in/",
  },
  {
    id: 9,
    name: "Income-Tax Department",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Income-Tax-Department.jpg",
    url: "https://www.incometaxindia.gov.in/Pages/default.aspx",
  },
  {
    id: 10,
    name: "Institute of Chartered Accountants of India",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Institute-of-Chartered-Accountants-of-India.jpg ",
    url: "https://www.icai.org/",
  },
  {
    id: 11,
    name: "Limited Liability Partnership",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Registrar-of-Companies.jpg ",
    url: "http://www.mca.gov.in/MinistryV2/llpact.html",
  },
  {
    id: 12,
    name: "Ministry of Finance ",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Ministry-of-Finance.jpg ",
    url: "https://www.finmin.nic.in/",
  },
  // {
  //   id: 13,
  //   name: "Municipal Corporation of Delhi",
  //   image: "https://gkkediaandco.com/wp-content/uploads/2019/06/mcd.jpg ",
  //   url: "http://www.mcdonline.gov.in/",
  // },
  {
    id: 14,
    name: "Registrar of Companies",
    image:
      "https://gkkediaandco.com/wp-content/uploads/2019/06/Registrar-of-Companies.jpg",
    url: "http://www.mca.gov.in/",
  },
  {
    id: 15,
    name: "Reserve Bank Of India",
    image: "https://gkkediaandco.com/wp-content/uploads/2019/06/rbi.jpg",
    url: "https://www.rbi.org.in/",
  },
];

export const Empanelments = [
  {
    id: 1,
    name: " Income Tax - Delhi, Principal Chief Commissioner of Income Tax (CCA) Empanelment for Special Audit u/s 142(2A) of the Income Tax Act, 1961.",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/empanelment/income-tax.png",
  },
  {
    id: 2,
    name: " Department of Trade & Taxes, New Delhi for Special Audit under Section 66 of Delhi Goods & Service Tax Act, 2017 of GST Registered Persons.",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/empanelment/deaprtmentslogo.png",
  },
  {
    id: 3,
    name: " State Bank of India Empanelment of Stock and Receivable Audit (SRA).",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/empanelment/sbi.png",
  },
  {
    id: 4,
    name: "Punjab National Bank Empanelment for Stock/Receivables Verification and Audit.",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/empanelment/pnb.png",
  },
  {
    id: 5,
    name: "The Jammu & Kashmir Bank Limited Empanelment for Stock Audit.",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/empanelment/jk-logo.png",
  },
  {
    id: 6,
    name: "Employees Provident Fund Organization (EPFO), Gurugram Empanelment for handling various Tax Matters and allied activities, Finalization of Books of Accounts, Special Audit, Audit of exempted PF Trust/establishment, Reconciliation of Specific Accounts or Book Keeping etc. (Re-audit of Exempted Establishment and Trust).",
    image:
      "https://gkkediaandco.com/wp-content/themes/gkkedia/images/empanelment/epfe.png",
    url: "https://rbi.org.in/scripts/fema.aspx",
  },
];

export const liveUpdates = [
  {
    id: 1,
    title: "DIR-3KYC",
    image: "https://i.ibb.co/dcWs6jB/quotes.jpg",
    desc1:
      "Attention to All DIN holders having DIN as on 31st March, 2022 KYC last date is 30 September 2022",
    desc2: "Non filling of KYC within due date levies late fees of Rs 5000/-",
    date: "October 7, 2022",
  },
  {
    id: 2,
    title:
      "CBDT extends due date for filling of various reports of audit for the Assessment Year 2022-23 under the Income-tax Act,1961",
    image: "https://i.ibb.co/dcWs6jB/quotes.jpg",
    desc1:
      "On consideration of difficulties faced by the taxpayers and other stakeholders in filling of various reports of audit for the assessment Year 2022-23 under the Income-tax Act,1961(Act), Central Board Of Direct Taxes (CBDT) has decided to extend the due date for filling of various reports of audit for the Assessment Year 2022-23, which was 30th September, 2022 to 7th October, 2022 in the case of certain category of assessees for whom the due was 30.09.2022.",
    desc2:
      "CBDT Circular No.    19/2022 in F.No.225/49/2022/ITA-|| dated 30.09.2022 issued.",
    date: "October 27, 2022",
  },
  {
    id: 3,
    title: "CBDT extends the due date",
    image: "https://i.ibb.co/dcWs6jB/quotes.jpg",
    desc1:
      "CBDT extends the due date for filling of various reports of audit for Assessment Year  2022-23 from 30th September, 2022 to 7th October, 2022 for certain categories of assesses. Circular No. 19/2022 dated 30.09.2022 issued.",
    desc2: "Non filling of KYC within due date levies late fees of Rs 5000/-",
    date: "October 7, 2022",
  },
  {
    id: 4,
    title:
      "On consideration of difficulties reported by taxpayers / stakeholders due to Covid",
    image: "https://i.ibb.co/dcWs6jB/quotes.jpg",
    desc1:
      "On consideration of difficulties reported by taxpayers / stakeholders due to Covid &#038; in e-filing  of Audit reports  for AY 2021-22 under the IT Act, 1961,CBDT further extends due dates for filing of Audit reports &#038; ITRs for AY 21-22. Circular No. 01/2022 dated 11.01.2022 issued",
    desc2: "Non filling of KYC within due date levies late fees of Rs 5000/-",
    date: "January 13, 2022",
  },
  {
    id: 5,
    title: "Due Dates for ITR",
    image: "https://i.ibb.co/dcWs6jB/quotes.jpg",
    desc1: "Due Dates for ITR(Tax Audit/Tp Assessee) Extended to 15.03.2022",
    desc2: "Non filling of KYC within due date levies late fees of Rs 5000/-",
    date: "January 13, 2022",
  },
  {
    id: 6,
    title: "Due Dates For Audit Report",
    image: "https://i.ibb.co/dcWs6jB/quotes.jpg",
    desc1: "Due Dates For Audit Report (TAR/TP) Extended to 15.02.2022",
    desc2: "Non filling of KYC within due date levies late fees of Rs 5000/-",
    date: "January 13, 2022",
  },
  {
    id: 7,
    title: "Extension of Last date for submitting",
    image: "https://i.ibb.co/dcWs6jB/quotes.jpg",
    desc1:
      "xtension of Last date for submitting M.E.F. 2021-22 from 20th November 2021 to 25 November 2021",
    desc2: "Non filling of KYC within due date levies late fees of Rs 5000/-",
    date: "November 22, 2022",
  },
];
