import React from "react";
import NavHeader from "../../../components/NavHeader";

const Due = () => {
  return (
    <div>
      <NavHeader name={"DUE DILIGENCE SERVICES"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2021/08/Resize-Image.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">DUE DILIGENCE SERVICES</h3>
                <ul>
                  <li>Financial Due Diligence</li>
                  <li>Legal Due Diligence</li>
                  <li>Taxes Due Diligence</li>
                  <li>Asset Purchase Due Diligence</li>
                  <li>Management Due Diligence</li>
                  <li>Administrative Due Diligence</li>
                  <li>Commercial Due Diligence</li>
                  <li>Micro-environmental Due Diligence</li>
                  <li>Technical Due Diligence</li>
                  <li>Human Resources Due Diligence</li>
                  <li>Intellectual Property Due Diligence</li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Due;
