export const aboutUs = [
  {
    id: 1,
    title: "OverView",
    url: "/about/",
  },
  // {
  //   id: 2,
  //   title: "Journey & Milestones",
  //   url: "/journey-and-milestones/",
  // },
];

export const services = [
  {
    id: 1,
    title: " Auditing & Assurance",
    url: "/services/auditing-assurance/",
  },
  {
    id: 2,
    title: "Income Tax",
    url: "/services/income-tax/",
  },
  {
    id: 3,
    title: "Goods & Services Tax (GST)",
    url: "/services/goods-services-tax-gst/",
  },
  {
    id: 4,
    title: "Accounting",
    url: "/services/accounting/",
  },
  {
    id: 5,
    title: "Company Law Matters Consultancy",
    url: "/services/company-law-matters-consultancy/",
  },
  {
    id: 6,
    title: "Startup Services",
    url: "/services/startup-services/",
  },
  {
    id: 7,
    title: "Societies and Trust (NGO)",
    url: "/services/societies-and-trust-ngo/",
  },
  {
    id: 8,
    title: "Finance",
    url: "/services/finance/",
  },
  {
    id: 9,
    title: "Business Process Outsource (BPO)",
    url: "/services/business-process-outsource-bpo/",
  },
  {
    id: 10,
    title: "CA Certification",
    url: "/services/ca-certification",
  },

  // {
  //   id: 16,
  //   title: "Import-Export, Technology Park & Special Economic Zone",
  //   url: "/services/import-export-consultancy/",
  // },
  // {
  //   id: 17,
  //   title: "Finance",
  //   url: "/services/finance/",
  // },
  // {
  //   id: 18,
  //   title: " Business Process Outsource (BPO)",
  //   url: "/services/business-process-outsource-bpo/",
  // },
  // {
  //   id: 19,
  //   title: "Matters related with Benami Property Transactions",
  //   url: "/services/matters-related-with-benami-property-transactions/",
  // },
  // {
  //   id: 20,
  //   title: " Matters related to Black Money and Imposition of Tax Act 2015",
  //   url: "/services/matters-related-to-black-money-and-imposition-of-tax-act-2015/",
  // },
  // {
  //   id: 21,
  //   title: " Matters related to Enforcement Directorate under PMLA & FEMA",
  //   url: "/services/matters-related-to-enforcement-directorate-under-prevention-of-money-laundering-act-pmla-fema/",
  // },
];

export const ourTeam = [
  {
    id: 1,
    title: "PRINCIPLE OWNER",
    url: "/founder-partner/",
  },
  {
    id: 2,
    title: "Professional Consultant",
    url: "/partners/",
  },
  {
    id: 3,
    title: "KEY STAFF",
    url: "/key-staff/",
  },
  {
    id: 4,
    title: "BUSINESS CONSULTANT",
    url: "/business-consultant/",
  },
];

export const resources = [
  // {
  //   id: 1,
  //   title: "Blogs",
  //   url: "/blogs/",
  // },
  {
    id: 2,
    title: "Important Links",
    url: "/important-link/",
  },
  // {
  //   id: 3,
  //   title: "Empanelment",
  //   url: "/empanelment/",
  // },
  {
    id: 4,
    title: "LATEST UPDATES",
    url: "/live-updates/",
  },
  // {
  //   id: 5,
  //   title: "Media Coverage",
  //   url: "/electronic-media-coverage/",
  // },
];
