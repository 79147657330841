import React from "react";
import NavHeader from "../../../components/NavHeader";
import "./style.css";
import * as Icon from "react-bootstrap-icons";

const Founder = () => {
  return (
    <div>
      <NavHeader name={"Founder"} />
      <section
        className="collections-gid-janus pt-5 pb-5"
        style={{ background: "#F0F0F0" }}
      >
        <div className="container patn">
          <div className="row borders">
            <div className="col-lg-5  px-lg-0">
              <div className="founder-partners-img">
                <img
                  src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/gk-imgs.jpg"
                  alt="Founder Partner"
                  className="img-fluid image1"
                />
              </div>
            </div>
            <div className="col-lg-7 progress-content-img px-lg-0 p-lg-5">
              <div className="founder-partners-img service-call">
                <h3 className="title-w3pvt2 mb-1">CA DHAVAL PANDYA </h3>
                <ul>
                  <li>B.Com, ACA</li>
                  <li>M. No. 604889 </li>
                  <li>Founder</li>
                  {/* <li>
                    Former Accountant Member of Income Tax Appellate Tribunal
                    (ITAT)
                  </li>
                  <li>Former Regional Council Member of NIRC of ICAI</li>
                  <li>Former Treasurer & Vice Chairman of NIRC of ICAI</li> */}
                  <li>Ph. No. : +91 9374542073</li>
                  <li>Executive Assistant : +91 9924242073</li>
                  <li>E-mail: pandya.dhaval38@gmail.com</li>
                </ul>
                <div className="socail-icon">
                  <p>
                    <b>Follow Me On </b>
                  </p>
                  <ul className="footer-social-media clearfix">
                    <li>
                      <a
                        href="https://www.facebook.com/PDHAVALCO?mibextid=ZbWKwL"
                        target="_blank"
                        style={{
                          background: "#888",
                          height: 30,
                          width: 30,
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          display: "flex",
                          borderRadius: 5,
                        }}
                      >
                        <Icon.Facebook
                          // className="fa fa-facebook"
                          color="white"
                          size={20}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/pdhavalco?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                        target="_blank"
                        style={{
                          background: "#888",
                          height: 30,
                          width: 30,
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          display: "flex",
                          borderRadius: 5,
                        }}
                      >
                        <Icon.Instagram
                          // className="fa fa-instagram"
                          color="white"
                          size={20}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/ca-dhaval-pandya-070b8a12a"
                        target="_blank"
                        style={{
                          background: "#888",
                          height: 30,
                          width: 30,
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          display: "flex",
                          borderRadius: 5,
                        }}
                      >
                        <Icon.Linkedin
                          // className="fa fa-youtube-play"
                          color="white"
                          size={20}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row borders">
            <div className="col-lg-12 progress-content-img px-lg-0 p-lg-5">
              <div className="founder-team-details service-call">
                <h3
                  className="title-w3pvt2 mb-2"
                  style={{
                    textAlign: "left",
                    textTransform: "capitalize",
                    fontSize: 20,
                  }}
                >
                  Introduction of Founder
                </h3>
                <p>
                  CA Dhaval Pandya is the “Founder” of the Firm. Born in
                  December 1991. He has qualified Chartered Accountant along
                  with Commerce graduation.
                </p>
                <p>
                  An Academic journey of CA Dhaval Pandya was very tough and
                  time consuming but with calm nature and huge patience, he
                  started believing and understanding of academic process.
                  During the academic journey, he learned a lot about himself
                  and nurtured himself according to the situation and
                  conditions.
                </p>
                <p>
                  CA DHAVAL PANDYA has 6 years of pre-qualification strong
                  professional practice experience after that he started his own
                  Practice and set up Firm under “P DHAVAL & CO “Chartered
                  Accountants firm in April 2021. He has expertise in the area
                  of Accountancy and Taxation. He has also experienced in the
                  field of Income Tax, Audit, Accounting, Financial Services,
                  VAT & Sales Tax, Service Tax, Goods & Services Tax (GST), GST
                  Audit, etc.
                </p>
                <p>
                  <b>
                    CA DHAVAL PANDYA has served following organizations before
                    the formation of this Firm:
                  </b>
                </p>
                <ul>
                  <li>JAIN & GOLECHHA, Chartered Accountants, Ahmedabad.</li>
                  <li>PUJARA & CO., Chartered Accountants, Ahmedabad.</li>
                  <li>
                    INITOR GLOBAL, US ACCOUNTS & US TAXATION OUTSOURCING
                    COMPANY, AHMEDABAD.
                  </li>
                  <li>
                    FALUCK International, Chemical Industry, Sanand GIDC,
                    Ahmedabad.
                  </li>
                </ul>
                <p></p>
                <p>
                  CA Dhaval Pandya is principal owner of Firm and he is
                  personally available for every small client for consulting
                  purpose. Since starting of firm, He has buildup 8 people team
                  and create associate network with Chartered Accountants,
                  Company secretaries, Business consultants so various services
                  they can provide services to their clients on one place.
                </p>
                <p>
                  CA Dhaval Pandya has served to numerous Client till date and
                  his core practicing area is Filing of Income tax Returns and
                  Response to Income tax Notices, Registration for Goods and
                  Service Tax and Filing of Monthly, Quarterly and Annual Return
                  Compliance of GST, Maintaining Books of Accounts and
                  Consulting Client as per their requirement, Auditing, CA
                  Certification.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="collections-gid-janus founder-m pt-5 pb-5"
        style={{
          background: "#352222",
        }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 progress-content-img px-lg-0 p-lg-5">
              <div className="founder-team-details service-call text-center">
                <h3 className="title-w3pvt2 mb-5">
                  From A Small Child With Dreams To Becoming A Leader With A
                  Powerful Vision.
                  <br /> Watch CA Gopal Kumar Kedia's Life Story So Far!{" "}
                </h3>
                <div className="gk-video">
                  <iframe
                    id="youtube-5345"
                    allowfullscreen="1"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="G"
                    src="https://www.youtube.com/embed/460GtTtwkfE?playlist=460GtTtwkfE&loop=1"
                    tabindex="-1"
                    width="100%"
                    height="500"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Founder;
