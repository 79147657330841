import * as Icon from "react-bootstrap-icons";
import { services } from "./headerData";
// import * as Icon from "react-bootstrap-icons";

export const ourServices = services.map((item) => {
  return (
    <li key={item.id} style={{ marginTop: 10 }}>
      <a
        href={item.url}
        style={{
          color: "white",
          textDecoration: "none",
        }}
      >
        {item.title}
      </a>
    </li>
  );
});

export const middleSection = [
  {
    id: 1,
    title: "Auditing & Assurance",
    url: "/services/auditing-assurance/",
  },
  {
    id: 2,
    title: "International Taxation",
    url: "",
  },
  {
    id: 3,
    title: "Company Law Matters Consultancy",
    url: "",
  },
  {
    id: 4,
    title: "Forensic Audit Services",
    url: "",
  },
  {
    id: 5,
    title: "Secretarial Services & SEBI related work",
    url: "",
  },
  {
    id: 6,
    title: "Accounting",
    url: "",
  },
  {
    id: 7,
    title: "Startup Services",
    url: "",
  },
  {
    id: 8,
    title: "Foreign Exchange Management Act",
    url: "",
  },
];

export const middleSections = middleSection.map((item) => {
  return (
    <li key={item.id} style={{ marginTop: 10 }}>
      <a
        href={item.url}
        style={{
          color: "white",
          textDecoration: "none",
        }}
      >
        <span className="fa fa-angle-double-right" aria-hidden="true"></span>
        {item.title}
      </a>
    </li>
  );
});

export const quickLink = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About Us",
    url: "/about/",
  },
  // {
  //   id: 3,
  //   title: "Journey & Milestones",
  //   url: "/journey-and-milestones/",
  // },
  {
    id: 4,
    title: "Services",
    url: "/services",
  },
  {
    id: 5,
    title: "Professional Consultant",
    url: "/partners/",
  },
  // {
  //   id: 6,
  //   title: "Infrastructure",
  //   url: "/infrastructure/",
  // },
  {
    id: 7,
    title: "Career",
    url: "/career/",
  },
  // {
  //   id: 8,
  //   title: "Employee Zone",
  //   url: "/",
  // },
];

export const quickLinks = quickLink.map((item) => {
  return (
    <li key={item.id} style={{ marginTop: 10 }}>
      <a
        href={item.url}
        style={{
          color: "white",
          textDecoration: "none",
        }}
      >
        {item.title}
      </a>
    </li>
  );
});

export const follow = [
  {
    id: 1,
    title: "Facebook",
    url: "https://www.facebook.com/PDHAVALCO?mibextid=ZbWKwL",
    icon: "House",
  },
  // {
  //   id: 2,
  //   title: "Twitter",
  //   url: "https://www.linkedin.com/in/ca-dhaval-pandya-070b8a12a",
  // },
  {
    id: 3,
    title: "Instagram",
    url: "https://instagram.com/pdhavalco?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
  },
  {
    id: 4,
    title: "LinkedIn",
    url: "https://www.linkedin.com/in/ca-dhaval-pandya-070b8a12a",
  },
  // {
  //   id: 5,
  //   title: "Youtube",
  //   url: "https://www.youtube.com/",
  // },
];

export const followUs = follow.map((item) => {
  return (
    <li key={item.id} style={{ marginTop: 10 }}>
      {/* <Icon className="fa" aria-hidden="true" size={18} /> */}
      <a
        href={item.url}
        style={{
          color: "white",
          fontSize: 16,
          fontWeight: "400",
          textDecoration: "none",
        }}
      >
        • {item.title}
      </a>
    </li>
  );
});

export const payment = [
  {
    id: 1,
    title: "Pay By Card",
    url: "",
  },
  {
    id: 2,
    title: "Pay By Paytm",
    url: "",
  },
];

export const paymentMode = payment.map((item) => {
  return (
    <li key={item.id} style={{ marginTop: 10 }}>
      <a
        href={item.url}
        style={{
          color: "white",
          textDecoration: "none",
        }}
      >
        {item.title}
      </a>
    </li>
  );
});
