import React from "react";
import NavHeader from "../../../components/NavHeader";

const Accounting = () => {
  return (
    <div>
      <NavHeader name={"Accounting"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/acounting-1.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Accounting</h3>
                <p>
                  The history of accounting goes back a 1000 years, but the
                  relevance of it still stays strong, today! Keeping up with the
                  multiple aspects of a business can be difficult, and that is
                  why we are here to offer bookkeeping, succession planning and
                  strategic planning, so that you can focus on other important
                  matters and not worry about the financial accounting of your
                  organization.
                </p>
                <ul>
                  <li>
                    Book Keeping and Accounting for Individuals, Firms, NGOs and
                    Companies including foreign Companies
                  </li>
                  <li>
                    Design, implementation and review of accounting manual
                  </li>
                  <li>
                    Advice on various Accounting Standards, Ind AS &#038; IFRS
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Accounting;
