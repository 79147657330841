import React from "react";
import NavHeader from "../../../components/NavHeader";

const Secretarial = () => {
  return (
    <div>
      <NavHeader name={"SECRETARIAL SERVICES & SEBI RELATED WORK"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2021/07/SEBI.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">
                  SECRETARIAL SERVICES & SEBI RELATED WORK
                </h3>
                <ul>
                  <li>Secretarial Compliance Audit</li>
                  <li>
                    Consultancy services related to adherence to the SEBI (LODR)
                    , Regulations 2015
                  </li>
                  <li>Corporate Secretarial services</li>
                  <li>Due Diligence services</li>
                  <li>
                    Consultation services on Intellectual Property Rights (IPR)
                    including trademark registration, royalty agreement,
                    Intellectual Property Rights enforcement and so on.
                  </li>
                  <li>
                    Advisory services on different SEBI Norms such as SEBI
                    Takeover Code, SEBI Portfolio Managers Regulations, SEBI
                    Insider Trading Regulations, SEBI Broker Regulations related
                    to IPO, SEBI (ICDR) Regulations, 2009, Public Issue, Rights
                    Issue, Preferential Allotment, Bonus Issue, Delisting, QIP
                    etc.
                  </li>
                  <li>
                    Legal guidance &#038; help in filing of different forms
                    under SEBI Prohibition of Insider Trading) Regulations,
                    1992.
                  </li>
                  <li>
                    Legal guidance &#038; help in Delisting of Companies from
                    Stock Exchanges under SEBI following the Delisting of Equity
                    Sharer Regulations, 2009.
                  </li>
                  <li>
                    Legal guidance &#038; help in procurement of various
                    regulatory certifications like from NSE/SEBI / BSE.
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Secretarial;
