import React from "react";
import NavHeader from "../../../components/NavHeader";
import "./style.css";
import { liveUpdates } from "../../../constant/servicesData";

const Live = () => {
  return (
    <div>
      <NavHeader name={"Live Updates"} />
      <section className="content-info">
        <div style={{ background: "#f5f5f5" }}>
          <div className="container">
            <section className="row py-5">
              {liveUpdates.map((item) => {
                return (
                  <article className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="testimonialBox text-center">
                      <img src={item.image} alt="" />
                      <h2 style={{ color: "black" }}>{item.title}</h2>
                      <p className="">
                        <p>{item.desc1}</p>
                        <p>{item.desc2}</p>
                      </p>

                      <div className="row">
                        {/* <div className="col-sm-6 col-md-6 float-left pr-2 lureadmore">
                          <a
                            // href="https://gkkediaandco.com/latestupdates/dir-3kyc/"
                            className=""
                          >
                            Explore More
                          </a>
                        </div> */}
                        <div className="col-sm-6 float-left testimonialUser text-truncate pl-0 d-none d-lg-block">
                          <span className="mainHeading">
                            <b>Dt.- </b>
                            {item.date}
                          </span>
                        </div>
                      </div>
                    </div>
                  </article>
                );
              })}
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Live;
