import React from "react";
import "./style.css";
import * as Icon from "react-bootstrap-icons";

const SideSocialMenu = () => {
  return (
    <div>
      {/* <div className="icon-bar d-none d-sm-block">
        <a href="https://www.facebook.com/" className="facebook1">
          <Icon.Facebook />
        </a>
        <a href="https://twitter.com/" className="twitter1">
          <Icon.Twitter />
        </a>
        <a href="https://www.linkedin.com" className="linkedin">
          <Icon.Linkedin />
        </a>
        <a href="https://www.youtube.com" className="youtube">
          <Icon.Youtube />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=+911234567890&amp;text=Hello P Dhaval and Co.!"
          className="whatsapp"
        >
          <Icon.Whatsapp />
        </a>
      </div> */}
      <div className="right-button">
        <a href="/contact/">
          <img
            src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/buttion-s.jpg"
            alt="Enquire now for CA in Delhi"
          />
        </a>
      </div>
    </div>
  );
};

export default SideSocialMenu;
