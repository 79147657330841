import React from "react";
import "./style.css";
import * as Icon from "react-bootstrap-icons";
import {
  aboutUs,
  ourTeam,
  resources,
  services,
} from "../../constant/headerData";
import mainImage from "../../assets/images/logo.png";

const Header = () => {
  return (
    <header className="header">
      <div className="container-2">
        <div className="header d-lg-flex justify-content-between align-items-center">
          <div className="header-section">
            <h1>
              <a className="navbar-brand logo editContent" href="/">
                <img
                  src={mainImage}
                  alt="best ca in ahmedabad"
                  title="PDhaval Chartered Accountants"
                  style={{
                    width: 120,
                    height: 70,
                  }}
                />
              </a>
            </h1>
          </div>
          <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <div className="dropdown">
                      <a href="/about/">
                        About Us
                        {/* <Icon.ChevronDown color="#000" /> */}
                      </a>
                      {/* <div className="dropdown-content">
                        {aboutUs.map((item) => {
                          return <a href={item.url}>{item.title}</a>;
                        })}
                      </div> */}
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <a href="/services/">
                        Services <Icon.ChevronDown color="#000" />
                      </a>
                      <div className="dropdown-content">
                        {services.map((item) => {
                          return <a href={item.url}>{item.title}</a>;
                        })}
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <a href="/our-team/">
                        Our Team <Icon.ChevronDown color="#000" />
                      </a>
                      <div className="dropdown-content">
                        {ourTeam.map((item) => {
                          return <a href={item.url}>{item.title}</a>;
                        })}
                      </div>
                    </div>
                  </li>
                  {/* <li className="nav-item">
                    <a href="/infrastructure/">Infrastructure</a>
                  </li> */}
                  <li className="nav-item">
                    <a href="/career/">Career</a>
                  </li>
                  <li>
                    <a href="/gallery/">Gallery</a>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <a href="/partners/">
                        {" "}
                        Resources <Icon.ChevronDown color="#000" />
                      </a>
                      <div className="dropdown-content">
                        {resources.map((item) => {
                          return <a href={item.url}>{item.title}</a>;
                        })}
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a href="/contact/">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
