import React from "react";
import Banner from "../../components/Banner";
import Information from "../../components/Information";
import LatestBlogs from "../../components/LatestBlogs";
import LatestCoverage from "../../components/LatestCoverage";
import LiveUpdates from "../../components/liveUpdates";
import OurServices from "../../components/OurServices";
import TreeStructure from "../../components/TreeStructure";
import WhyWeAre from "../../components/WhyWeAre";

const Home = () => {
  return (
    <div>
      {/* <Banner /> */}
      {/* <Information /> */}
      <TreeStructure />
      <LiveUpdates />
      <OurServices />
      <WhyWeAre />
      {/* <LatestBlogs /> */}
      {/* <LatestCoverage /> */}
    </div>
  );
};

export default Home;
