import React from "react";
import NavHeader from "../../../components/NavHeader";
import "./style.css";

const Investment = () => {
  return (
    <div>
      <NavHeader name={"INVESTMENT IN INDIA BY FOREIGN NATIONALS & NRIS"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2021/08/Resize-Image.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">
                  INVESTMENT IN INDIA BY FOREIGN NATIONALS & NRIS
                </h3>

                <p>
                  {" "}
                  With the invent of Covid-19 Corona Virus, everything in the
                  world is bound to change. The way businesses are being
                  operated will also change. In the changed scenario, India is
                  going to be hub for Multinational Companies. It is forecasted
                  that the Multinational Companies will shift from China to
                  other Countries. India is biggest contender to provide
                  platform to Multinational Companies due to following reasons:
                </p>
                <ul>
                  <li style={{ fontSize: 20, marginTop: 20 }}>
                    Starting Up Services
                    <ul>
                      <li>Incorporation of Company in India</li>
                      <li>Business Address usance (Virtual Office)</li>
                      <li>Director Services</li>
                      <li>
                        Obtaining all the required Licences &#038; permissions
                      </li>
                      <li>Setting up Policies &#038; Procedures</li>
                      <li>India Entry Services</li>
                    </ul>
                  </li>
                  <li style={{ fontSize: 20, marginTop: 20 }}>
                    {" "}
                    Fixed Assets Acquisition related Services
                    <ul>
                      <li>Purchase of Land</li>
                      <li>Construction of Building</li>
                      <li>Purchase of Other Fixed Assets</li>
                    </ul>
                  </li>
                  <li style={{ fontSize: 20, marginTop: 20 }}>
                    Human Resources related Services
                    <ul>
                      <li>Hiring of Man Power</li>
                      <li>Payroll Services</li>
                      <li>HR Services</li>
                    </ul>
                  </li>
                  <li style={{ fontSize: 20, marginTop: 20 }}>
                    Operational Services
                    <ul>
                      <li>Book Keeping &#038; Accountancy Services</li>
                      <li>GST Compliances</li>
                      <li>TDS Compliances</li>
                      <li>Preparation and Filing of Income Tax Return</li>
                      <li>Company Law (ROC) Compliances</li>
                    </ul>
                  </li>
                  <li style={{ fontSize: 20, marginTop: 20 }}>
                    Growth Services
                    <ul>
                      <li>Concept Planning and Testing</li>
                      <li>Identifying possible growth opportunities</li>
                      <li>Pricing</li>
                      <li>Cash flow analysis</li>
                      <li>Conduct financial and risk analysis</li>
                      <li>Audit support and preparation</li>
                      <li>Financial forecasting</li>
                      <li>Budget creation</li>
                    </ul>
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Investment;
