import React from "react";
import NavHeader from "../../components/NavHeader";
import "./style.css";

const Career = () => {
  return (
    <div>
      <NavHeader name={"Career"} />
      <section className="content-info">
        <div className="container py-md-5">
          <div className="sidebar-inner wow fadeInUp" data-wow-duration="3s">
            <div className="row jobtab">
              <div className="col-md-12"></div>
            </div>
            <div className="row pt-5">
              <div className="col-lg-12 col-md-12 contact-sidebar-section">
                <div
                  className="contact-form contact-form-one"
                  style={
                    {
                      // borderTop: 1,
                      // borderStyle: "solid",
                      // borderColor: "#ddd",
                    }
                  }
                >
                  <h3>Apply below to find your future job!</h3>
                  <div id="form-messages"></div>
                  <form
                  // method="post"
                  // id="contact-form"
                  // action="https://gkkediaandco.com/wp-content/themes/gkkedia/career-mails.php"
                  // enctype="multipart/form-data"
                  >
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="text"
                            placeholder="Name"
                            id="name"
                            name="name"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="email"
                            placeholder="E-Mail ID"
                            id="email"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="tel"
                            placeholder="Phone No."
                            id="phone_number"
                            name="phones"
                            required=""
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-6 col-sm-12"
                        style={{ marginTop: 20 }}
                      >
                        <label
                          className="fileContainer"
                          style={{ textAlign: "left" }}
                        >
                          Upload Your Resume
                          <input type="file" name="doc" />
                        </label>
                      </div>
                      {/* <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="text"
                            placeholder="Qualification"
                            id="qualification"
                            name="qualification"
                            required=""
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      {/* <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <select
                            name="jobprofile"
                            required=""
                            className="form-control"
                            style={{ height: 43 }}
                          >
                            <option value="">Apply For</option>
                            <option value="Accountants">Accountants</option>
                            <option value="Article Assistant">
                              {" "}
                              Article Assistant
                            </option>
                            <option value="CA">CA</option>
                            <option value="Company Secretary">
                              Company Secretary
                            </option>
                            <option value="Field Boy"> Field Boy</option>
                            <option value="HR"> HR</option>
                            <option value="PA">PA</option>
                            <option value="Peon">Peon</option>
                            <option value="Receptionist">Receptionist</option>
                          </select>
                        </div>
                      </div> */}
                      {/* <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="text"
                            placeholder="Experience"
                            id="experience"
                            name="experience"
                            required=""
                          />
                        </div>
                      </div> */}
                    </div>

                    <div className="row">
                      {/* <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="date"
                            placeholder="Date of Birth"
                            id="dob"
                            name="dob"
                            required=""
                            onchange="this.className=(this.value!=''?'has-value':'')"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <select
                            name="marital_status"
                            required=""
                            className="form-control"
                            style={{ height: 43 }}
                          >
                            <option value="">Marital Status</option>
                            <option value="Married">Married</option>
                            <option value="Unmarried">Unmarried</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="form-field">
                              <select
                                name="height_ft"
                                required=""
                                className="form-control"
                                style={{ height: 43 }}
                              >
                                <option value="">Height (ft.)</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-field">
                              <select
                                name="height_in"
                                required=""
                                className="form-control"
                                style={{ height: 43 }}
                              >
                                <option value="">Height (in.)</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="text"
                            placeholder="Weight"
                            id="weight"
                            name="weight"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ alignItems: "center" }}>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <select
                            name="work_firm"
                            required=""
                            className="form-control"
                            style={{ height: 43 }}
                          >
                            <option value="">Whether Worked in CA Firm </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div> */}
                      {/* <div
                        className="col-md-6 col-sm-12"
                        style={{ marginTop: 20 }}
                      >
                        <label className="fileContainer">
                          Upload Your Resume
                          <input type="file" name="doc" required />
                        </label>
                      </div> */}
                    </div>
                    {/* <div className="row" style={{ alignItems: "center" }}>
                      <div className="col-md-6 col-sm-12">
                        <label className="fileContainer">
                          Upload Your Photo
                          <input type="file" name="photo" required />
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="text"
                            placeholder="Expected date of joining, if selected"
                            id="expected_date"
                            name="expected_date"
                            required=""
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="text"
                            placeholder="Current Salary"
                            id="current_salary"
                            name="current_salary"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="text"
                            placeholder="Expected Salary"
                            id="expected_salary"
                            name="expected_salary"
                            required=""
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-field">
                      <textarea
                        placeholder="Address"
                        rows="7"
                        id="address"
                        name="address"
                        required=""
                      ></textarea>
                    </div>
                    <div className="form-field">
                      <textarea
                        placeholder="Message"
                        rows="7"
                        id="message"
                        name="msg"
                        required=""
                      ></textarea>
                    </div> */}
                    <div
                      className="form-button"
                      style={{ marginBottom: 20, marginTop: 20 }}
                    >
                      <button type="submit" name="submit" className="readon">
                        Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Career;
