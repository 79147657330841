import React from "react";
import NavHeader from "../../../components/NavHeader";

const Foreign = () => {
  return (
    <div>
      <NavHeader name={"FOREIGN EXCHANGE MANAGEMENT ACT"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/FOREIGN-EXCHANGE-MANAGEMENT-ACT.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">FOREIGN EXCHANGE MANAGEMENT ACT</h3>
                <p>
                  Our purpose is in sync with the Government&#8217;s overall
                  vision, where we facilitate external trade and payments and
                  promote orderly development and maintenance of the foreign
                  exchange market in India. Observing the current global
                  scenario is important while analyzing the market, and taking
                  certain risk factors into account are also pivotal, as they
                  help is providing the right course of action for foreign
                  exchange management.{" "}
                </p>
                <ul>
                  <li>FEMA related consultancy</li>
                  <li>
                    FDI (Foreign Direct Investment) related matters &#038;
                    consultancy
                  </li>
                  <li>Filing of FIRMS &#038; FC-GPR Forms with RBI</li>
                  <li>
                    Filing of FLA (Foreign Liabilities &#038; Assets) Return
                    with RBI
                  </li>
                  <li>
                    ECB (External Commercial Borrowing) related matters &#038;
                    consultancy
                  </li>
                  <li>
                    ODI (Overseas Direct Investment) related matters &#038;
                    consultancy
                  </li>
                  <li>NRI &#038; PIO related matters &#038; consultancy</li>
                  <li>All types of Reserve Bank of India approval </li>
                  <li>
                    Formation of Liaison office, Project Office &#038; Branch
                    Office
                  </li>
                  <li>Wholly owned subsidiary and a joint venture in India</li>
                  <li>Assistance in setting up Permanent Establishment (PE)</li>
                  <li>Branch Office in Special Economic Zone</li>
                  <li>Foreign Trade Policy Assistance</li>
                  <li>Inbound and Outbound Investment Strategies</li>
                  <li>Approval from Ministry of Finance</li>
                  <li>
                    Assistance in drafting of foreign collaboration agreements
                  </li>
                  <li>Advisory on change/ exit in existing Business model.</li>
                  <li>
                    Advisory on Outbound Structuring as the choice of
                    appropriate jurisdiction, structuring of investment etc.
                  </li>
                  <li>
                    Registration of Non-Banking Finance Companies (NBFC) with
                    Reserve Bank of India
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Foreign;
