import React from "react";
import NavHeader from "../../../components/NavHeader";
import { useLocation, useParams } from "react-router-dom";
import { ContentSection } from "../../../components/ContentSection";

export const PartnerCommonPage = ({}) => {
  const { state } = useLocation();
  const { id } = useParams();
  console.log("State", state);
  return (
    <div>
      <NavHeader name={state.data.name} />
      <section
        class="collections-gid-janus pt-5 pb-5"
        style={{ background: "#f0f0f0" }}
      >
        <div class="container patn ">
          <div class="row borders">
            <div class="col-lg-2 progress-content-img px-lg-0 p-lg-5">
              <div class="founder-partners-img">
                <img
                  src={state.data.image}
                  alt="CA Sangeeta Singh"
                  class="img-fluid image1"
                />
              </div>
            </div>
            <div class="col-lg-10 progress-content-img px-lg-0 p-lg-5">
              <div class="founder-partners-img founder-partners-img2">
                <h3 class="title-w3pvt2 mb-1">{state.data.name}</h3>
                <ul>
                  <li>{state.data.qualification}</li>
                  <li>M. No. {state.data.MNo}</li>
                  <li>{state.data.post}</li>
                  <li>Mobile : {state.data.mobile}</li>
                  <li>E-mail: {state.data.email}</li>
                </ul>
              </div>
            </div>

            <div
              class="col-lg-12 progress-content-img px-lg-0 p-lg-5"
              style={{ paddingTop: 0 }}
            >
              <div class="founder-team-details service-call">
                <h2 class="title-w3pvt2 mb-2">Background & Experience </h2>
                <p>
                  <p>{state.data.description}</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
