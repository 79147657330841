import React from "react";
import NavHeader from "../../components/NavHeader";
import "./style.css";

const About = () => {
  return (
    <div>
      <NavHeader name={"About"} />
      <section className="collections-gid-janus" id="skills">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-6 progress-content-img  wow fadeInLeft"
              data-wow-duration="1s"
            >
              <img
                src="https://lh3.googleusercontent.com/p/AF1QipNssJrnQ8Wz2-_--v5KvNrXjbnv4Ks6auvHZOmK=s1360-w1360-h1020"
                alt="Swati Trinity"
                className="img-fluid image1"
                style={{ marginTop: 150 }}
              />
            </div>
            <div
              className="col-lg-6 progress-content text-left p-lg-3 my-lg-3 wow fadeInRight"
              data-wow-duration="1s"
            >
              <div className="baout-r-cantt">
                <h3 className="title-w3pvt mb-4">About us</h3>
                <p>
                  We are Firm of Chartered Accountants having experience of more
                  than two years and we started our journey as professional
                  Chartered accountant since 2021. We are having team of 8
                  people and associated with 2 Chartered Accountant Firms and 1
                  Company Secretary Firm.{" "}
                </p>
                <p>
                  CA Dhaval Pandya is principal owner of Firm and he is
                  personally available for every small client for consulting
                  purpose. Since starting of firm, He has buildup 8 people team
                  and create associate network with Chartered Accountants,
                  Company secretaries, Business consultants so various services
                  they can provide to their clients on one place.
                </p>
                <p>
                  We are dealing with industries like Construction Industry,
                  Manpower Industry, Various Retail Traders and Distributors of
                  Tyre and Tubes and Manufacturing Industries. We provide them
                  Bookkeeping Service, GST Registration and GST Return Filing
                  Service and Company and LLP Incorporation and their Statutory
                  Compliance, Project Financing for their business. we are also
                  handling their Income tax Return Filing Services and
                  Responding to Income tax Notices & Assessment.
                </p>
                <p>
                  We are also dealing in arrangement of funds for our Various
                  Clients from the Bank and NBFC so they can get Bank Financing
                  Facility like Term Loan, Cash Credit, Letter of Credit, Over
                  Draft Facilities, Bills Discounting, Home loan, Machinery Loan
                  and Auto Loan Products hassle-free.{" "}
                </p>
                <p>
                  Further, we are doing Certification work like Net Worth
                  Certificates, Turnover Certificates, Fund Utilization
                  Certificates for NGOs etc. of our clients. We are also
                  providing Intellectual Property Services like Trademark,
                  Copyright & Patent application and allied matters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
