import React from "react";
import "./style.css";
import NavHeader from "../../../components/NavHeader";
import { ImportantLinks } from "../../../constant/servicesData";

const Important = () => {
  return (
    <div>
      <NavHeader name={"Important Link"} />
      <section class="content-info">
        <div style={{ background: "#f5f5f5" }}>
          <div class="container">
            <div
              id="rs-contact"
              class="rs-contact contact-section  pt-100 pb-100"
            >
              <div class="container">
                <div class="row contact-address-section">
                  {ImportantLinks.map((item) => {
                    return (
                      <div
                        class="col-md-4 pbb wow fadeInUp"
                        data-wow-duration="1s"
                      >
                        <div class="important borders">
                          <a href={item.url} target="_blank">
                            {" "}
                            <img
                              src={item.image}
                              class="img-fluid"
                              alt="logo"
                            />
                          </a>
                          <h4>{item.name}</h4>
                          <div class="btn-area pt-20">
                            <a
                              href={item.url}
                              target="_blank"
                              class="readon2 blog-btn"
                            >
                              View More
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Important;
