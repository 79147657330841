import React from "react";
import NavHeader from "../../../components/NavHeader";

const Societies = () => {
  return (
    <div>
      <NavHeader name={"Societies and Trust (NGO)"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/SOCIETIES-AND-TRUST-NGO.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">SOCIETIES AND TRUST (NGO)</h3>
                <p>
                  Charity begins at home and has countless ripples that affect
                  the societies and communities at large. Whether it is a trust
                  managed by a few people or a large section of the society, our
                  services provide the best financial advice and services. Trust
                  and societies form a pivotal pillar of the economic structure
                  of the country, hence it is our duty to ensure that your
                  organization stands on a strong and sustainable economic
                  pillar.{" "}
                </p>
                <ul>
                  <li>Formation of Society, Trust &#038; Section 8 Company</li>
                  <li>
                    Fresh Registration U/s 12A/ 12AA/ 12AB (Form 10AC) and U/s
                    80G (Form 10AC) of Income Tax Act
                  </li>
                  <li>
                    Provisional Registration U/s 12AB (Form 10AC) and U/s 80G
                    (Form 10AC) of Income Tax Act
                  </li>
                  <li>
                    Registration under Foreign Contribution Regulation Act
                    (FCRA)
                  </li>
                  <li>
                    Filing of Returns under Foreign Contribution Regulation Act
                    (FCRA)
                  </li>
                  <li>Issue of certificate of compliance of CSR Funds</li>
                  <li>
                    Assisting NGOs in filing Form 10BD – Statement of donations
                  </li>
                  <li>
                    Assisting NGOs in issuing Form 10BE – Certificate of
                    donation to the donors
                  </li>
                  <li>Compliances with ROC for CSR Activities</li>
                  <li>Compliances in NGO Darpan NITI Aayog</li>
                  <li>Donor Audit of NGO</li>
                  <li>Investigative Audit of NGO</li>
                  <li>Consultancy in above matters</li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Societies;
