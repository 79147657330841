import React, { useRef } from "react";
import "./style.css";
import Xarrow from "react-xarrows";

const boxStyle = {
  border: "grey solid 5px",
  borderRadius: "10px",
  padding: "15px",
  marginTop: 50,
  backgroundColor: "#352222",
};

const TreeStructure = () => {
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);
  const box4Ref = useRef(null);
  const box5Ref = useRef(null);
  const box6Ref = useRef(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: 50,
      }}
    >
      <div ref={box1Ref} style={boxStyle}>
        <h2> P Dhaval & Co</h2>
        <h5>Chartered Accountant</h5>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "100%",
          marginTop: 200,
        }}
      >
        <div ref={box2Ref} style={boxStyle}>
          <h5>Modern</h5>
        </div>
        <div ref={box3Ref} style={boxStyle}>
          <h5>Competence</h5>
        </div>
        <div ref={box4Ref} style={boxStyle}>
          <h5>Emerging</h5>
        </div>
        <div ref={box5Ref} style={boxStyle}>
          <h5>Professional</h5>
        </div>
        <div ref={box6Ref} style={boxStyle}>
          <h5>Result Oriented</h5>
        </div>
      </div>

      <Xarrow
        start={box1Ref}
        end={box2Ref}
        lineColor={"grey"}
        headColor={"#352222"}
        curveness={0.5}
      />
      <Xarrow
        start={box1Ref}
        end={box3Ref}
        lineColor={"grey"}
        headColor={"#352222"}
        curveness={0.5}
      />
      <Xarrow
        start={box1Ref}
        end={box4Ref}
        lineColor={"grey"}
        headColor={"#352222"}
        curveness={0.5}
      />
      <Xarrow
        start={box1Ref}
        end={box5Ref}
        lineColor={"grey"}
        headColor={"#352222"}
        curveness={0.5}
      />
      <Xarrow
        start={box1Ref}
        end={box6Ref}
        lineColor={"grey"}
        headColor={"#352222"}
        curveness={0.5}
        showHead={true}
      />
    </div>
  );
};

export default TreeStructure;
