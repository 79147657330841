import React from "react";
import NavHeader from "../../../components/NavHeader";
import "./style.css";

const Journey = () => {
  return (
    <div>
      <NavHeader name={"Journey and Milestones"} />
      <section
        className="collections-gid-janus bgg2 journeymiles pt-5 pb-5"
        style={{ background: "#F5F5F5" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-timeline">
                <div className="timeline">
                  <a href="#" className="timeline-content">
                    <div className="timeline-year">
                      <span>2020-2021</span>
                    </div>
                    <h3 className="title">2020-2021</h3>
                    <p className="description"></p>
                    <ul>
                      <li>
                        Addition of 3 Branch Offices in the district of
                        Kotkapura (Punjab), Faridabad (Haryana) and Raipur
                        (Chhattisgarh).
                      </li>
                      <li>
                        The firm proliferated with addition of partners to our
                        team from 8-9 partners in 2019 to 12-13 partners in
                        2021.{" "}
                      </li>
                      <li>
                        Accreditation of partners to the profile of our Partners
                        CA Rajnish Gupta and CA Deepak Tulsiyan.{" "}
                      </li>
                      <li>
                        CA Rajnish Gupta, Branch Partner of the Firm and in
                        charge of Punjab Branch, he has been certified from
                        Institute of Chartered Accountants of India in the field
                        of Concurrent Audit of Banks and Forensic Accounting
                        &amp; Fraud Detection.{" "}
                      </li>
                      <li>
                        CA Deepak Tulsiyan, a Branch Partner of the Firm in
                        charge of Haryana Branch, is a qualified Forensic
                        Auditor (ICAI) and Insolvency Professional (IBBI).
                      </li>
                      <li>
                        The number of employee/staff head has soared up from 93
                        in the year 2019 to 98 in the year 2021.
                      </li>
                      <li>
                        The company celebrated its grand and much awaited 28th
                        Foundation Day propelling the viewpoint of saving our
                        environment and advocating “Let’s Let Do Our Bit”- Save
                        Yourself, Save Others, Save Earth.{" "}
                      </li>
                      <li>
                        Mr. Gopal Kumar Kedia has been participating and has
                        recently visited Dainik Jagran, the National News
                        Channel of India and shared his views on Income Tax
                        Matters and Tax Reforms in India.
                      </li>
                      <li>
                        The scope and purview of service scale of the company
                        has widened with incorporating the following services
                        namely Merger &amp; Acquisition, Forensic Audit
                        Services, Due Diligence Services, Matters Related with
                        Benami Property Transactions, Matters related to Black
                        Money and Imposition of Tax Act 2015, Matters related to
                        Enforcement Directorate under PMLA and FEMA and services
                        related to International Taxation.
                      </li>
                    </ul>
                    <p></p>
                  </a>
                </div>
                <div className="timeline">
                  <a href="#" className="timeline-content">
                    <div className="timeline-year">
                      <span>2018-2019</span>
                    </div>
                    <h3 className="title">2018-2019</h3>
                    <p className="description"></p>
                    <ul>
                      <li>
                        In 2019, Paharganj office has been reconstructed with
                        all latest technology and infrastructure with modern
                        facilities measuring 6000 sq. feet for the staff and
                        staff strength increased to 93.
                      </li>
                      <li>
                        In 2019, Successful implementation of Software for Human
                        Resource Management, Client &amp; Staff Management and
                        CRM has done for growth of staff.
                      </li>
                      <li>
                        Mr. Gopal Kumar Kedia is regularly participating in
                        debate on Income-Tax &amp; GST matters on Television
                        News Channels, viz, Aaj Tak, India TV, ABP News, Lok
                        Sabha TV, News 24, Zee News, Zee Business, India News,
                        News Nation, etc.
                      </li>
                      <li>
                        Mr. Kedia also speaks on Income-Tax matters on All India
                        Radio which is aired on FM Channels like, Rainbow, Gold
                        etc.
                      </li>
                    </ul>
                    <p></p>
                  </a>
                </div>
                <div className="timeline">
                  <a href="#" className="timeline-content">
                    <div className="timeline-year">
                      <span>2014-2018</span>
                    </div>
                    <h3 className="title">2014-2018</h3>
                    <p className="description"></p>
                    <ul>
                      <li>
                        In 2014, Paharganj office area was extended from 1600
                        sq. feet to 3000 sq. feet by adding Accountant room and
                        extending Reception room.
                      </li>
                      <li>
                        In 2015, Mr. Gopal Kumar Kedia was appointed as the
                        Accountant Member of Income Tax Appellate Tribunal
                        (ITAT), a post which is equivalent to the Senior
                        Additional Secretary to Government of India, which he
                        resigned later on.
                      </li>
                      <li>
                        This was a moment of immense proud for the Firm as a
                        whole..
                      </li>
                      <li>
                        Mr. Gopal Kumar Kedia appeared on various television
                        channels like, Aaj Tak, LOK Sabha TV, India News, News
                        Nation, News 24, Zee Business, Zee News, etc. for
                        Economic and Taxation issues.
                      </li>
                      <li>
                        With multifold boost in operations of Firm, the Firm
                        shifted from 9th floor to 8th floor of Naurang House in
                        3000 sq. feet area.
                      </li>
                      <li>
                        The Firm metamorphosed from one room office to a big
                        swanky, high-end office equipped with latest technology
                        and infrastructure with modern facilities measuring 6000
                        sq. feet for the staff and staff strength increased to
                        82.
                      </li>
                    </ul>
                    <p></p>
                  </a>
                </div>
                <div className="timeline">
                  <a href="#" className="timeline-content">
                    <div className="timeline-year">
                      <span>2009-2014</span>
                    </div>
                    <h3 className="title">2009-2014</h3>
                    <p className="description"></p>
                    <ul>
                      <li>
                        Drawing motivation from his student days of being a
                        Treasurer in EICASA (Eastern India Chartered Accountant
                        Students’ Association) in the year 1985, he contested
                        again in elections of NIRC of ICAI.
                      </li>
                      <li>
                        In 2009, he won with second highest votes in elections
                        of NIRC of ICAI.
                      </li>
                      <li>
                        In 2010, the Firm acquired another office on 9th floor,
                        Naurang House, Connaught Place measuring 1000 sq. feet.
                      </li>
                      <li>
                        In 2011, he was elected as Treasurer of NIRC of ICAI for
                        the year 2011-12.
                      </li>
                      <li>
                        In 2012, he was elected as Vice- Chairman of NIRC of
                        ICAI for the year 2012-13.
                      </li>
                      <li>
                        In 2012 elections held by NIRC of ICAI, he won with the
                        highest ever votes in the history of ICAI.
                      </li>
                      <li>
                        A leading magazine BUREAUCRACY TODAY ranked the Firm as
                        the 8th most prominent &amp; upcoming CA Firm in Delhi
                        and 29th in India.
                      </li>
                    </ul>
                    <p></p>
                  </a>
                </div>
                <div className="timeline">
                  <a href="#" className="timeline-content">
                    <div className="timeline-year">
                      <span>2003-2008</span>
                    </div>
                    <h3 className="title">2003-2008</h3>
                    <p className="description"></p>
                    <ul>
                      <li>
                        In 2003, Mr. Gopal Kumar Kedia contested the elections
                        held by NIRC of ICAI and got 150 votes only.
                      </li>
                      <li>
                        Again in the year 2006, Mr. Gopal Kumar Kedia contested
                        the elections of NIRC of ICAI and got 250 votes only.
                      </li>
                      <li>
                        Expansion in the work load necessitated the expansion in
                        the workplace in Paharganj.
                      </li>
                      <li>
                        In 2007, Article room, Record room, Partners’ room and
                        Conference room were added in Paharganj extending office
                        size from 300 sq. feet to 1600 sq. feet.
                      </li>
                    </ul>
                    <p></p>
                  </a>
                </div>
                <div className="timeline">
                  <a href="#" className="timeline-content">
                    <div className="timeline-year">
                      <span>1998-2002</span>
                    </div>
                    <h3 className="title">1998-2002</h3>
                    <p className="description"></p>
                    <ul>
                      <li>
                        The Firm started garnering success but it was a slow
                        process.
                      </li>
                      <li>
                        Firm shifted from Gali No. 1 to Gali No. 6, Chuna Mandi,
                        Paharganj in a room measuring 150 sq. feet.
                      </li>
                      <li>
                        As operations grew, six employees became part of the
                        Firm.
                      </li>
                      <li>
                        Extended office area to 300 sq. feet by adding Reception
                        room.
                      </li>
                    </ul>
                    <p></p>
                  </a>
                </div>
                <div className="timeline">
                  <a href="#" className="timeline-content">
                    <div className="timeline-year">
                      <span>1994</span>
                    </div>
                    <h3 className="title">1994</h3>
                    <p className="description"></p>
                    <ul>
                      <li>Formation of CA Web &amp; Company</li>
                    </ul>
                    <p></p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Journey;
