import React from "react";
import NavHeader from "../../components/NavHeader";
import "./style.css";
import * as Icon from "react-bootstrap-icons";
import { CountryList, ourService } from "../../constant/servicesData";

const Contact = () => {
  return (
    <div>
      <NavHeader name={"Contact"} />
      <section className="content-info">
        <div className="bg11">
          <div className="container">
            <div
              id="rs-contact"
              className="rs-contact contact-section  pt-100 pb-100"
            >
              <div className="bg-gr">
                {/* <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tab-contact nav-tabs">
                      <li>
                        <a
                          className="active"
                          data-toggle="tab"
                          href="#newdelhi"
                        >
                          Delhi
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#PaharGanj">
                          Pahar Ganj
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Punjab">
                          Punjab
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Haryana">
                          Haryana{" "}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Raipur">
                          Chhattisgarh
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Australia">
                          Australia
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="address">
                      <div className="tab-content contact-address-section">
                        <div id="newdelhi" className="tab-pane in active">
                          <div className="row">
                            <div className="col-md-6">
                              <div
                                className="contact-info contact-address2"
                                style={{ marginTop: 20 }}
                              >
                                {/* <h4
                                  style={{
                                    textAlign: "left",
                                    marginLeft: 10,
                                  }}
                                >
                                  Head Office : Ahmedabad{" "}
                                </h4> */}
                                <p>
                                  <Icon.HouseDoorFill
                                    className="fa"
                                    aria-hidden="true"
                                    size={18}
                                  />
                                  <div style={{ marginLeft: 10 }}>
                                    {" "}
                                    B- 1208 SWATI TRINITY,
                                    <br /> NEAR APPLEWOODS TOWNSHIP,
                                    <br />
                                    SHELA LANDMARK SHANTIPURA CIRCLE,
                                    <br /> SP RING ROAD,
                                    <br /> AHMEDABAD
                                  </div>
                                </p>
                                <p>
                                  <Icon.TelephoneFill
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                    size={18}
                                  />
                                  <div style={{ marginLeft: 10 }}>
                                    +91 9924242073{" "}
                                  </div>
                                </p>
                                <p>
                                  <Icon.TelephoneFill
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                    size={18}
                                  />
                                  <div style={{ marginLeft: 10 }}>
                                    +91 9374542073{" "}
                                  </div>
                                </p>
                                {/* <p>
                                  <Icon.TelephoneFill
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                    size={18}
                                  />
                                  <div style={{ marginLeft: 10 }}>
                                    1800110099 (India Toll Free){" "}
                                  </div>
                                </p> */}
                                {/* <p>
                                  <Icon.PhoneFill
                                    className="fa fa-mobile"
                                    aria-hidden="true"
                                    size={18}
                                  />
                                  <div style={{ marginLeft: 10 }}>
                                    +91-8826897200
                                  </div>
                                </p> */}
                                <p>
                                  <Icon.EnvelopeFill
                                    className="fa fa-envelope"
                                    aria-hidden="true"
                                    size={18}
                                  />
                                  <div style={{ marginLeft: 10 }}>
                                    pandya.dhaval38@gmail.com
                                  </div>
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 mb-sm-30 map-contact">
                              <iframe
                                title="ahmedabad"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.8341859536486!2d72.46917557484994!3d22.99312411742745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b4edded74ef%3A0x2209f704b87c01f0!2sSWATI%20TRINITY!5e0!3m2!1sen!2sin!4v1693313134149!5m2!1sen!2sin"
                                width="520"
                                height="300"
                                style={{ border: 0 }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-md-5">
          <div className="sidebar-inner wow fadeInUp" data-wow-duration="3s">
            <div className="row" style={{ display: "flex" }}>
              <div className="col-lg-4 col-md-12">
                <div className="sider-contact">
                  <p className="cont">P Dhaval & Co.</p>
                  <div className="sidebar-text">
                    For any query, concern or financial advice and solution, get
                    in touch with us today!
                  </div>
                  <div className="sidebar-opening-hour">
                    <h4 className="white-color mb-15">Opening Hour</h4>
                    <ul>
                      <li>Mon - Sat: 9:30 am to 6 pm</li>
                      <li>Saturday: 10.00am to 4pm</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 contact-sidebar-section">
                <div className="contact-form contact-form-one contcat-form">
                  <div id="form-messages"></div>
                  <form
                    // method="post"
                    name="form"
                    action=""
                    className="ContactUsFormData"
                    id="ContactFormData"
                    data-parsley-validate=""
                    onsubmit=""
                  >
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            id="page_url"
                            name="page_url"
                            className="form-control"
                            type="hidden"
                            value="/contact/"
                          />
                          <input
                            id="form_type"
                            name="form_type"
                            className="form-control"
                            type="hidden"
                            value="Contact Form"
                          />
                          <input
                            type="text"
                            placeholder="Name"
                            id="name"
                            name="name"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="email"
                            placeholder="E-Mail Id"
                            id="email"
                            name="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                            autocomplete="off"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-field">
                          <input
                            type="tel"
                            placeholder="Phone No."
                            name="phone"
                            data-parsley-minlength="10"
                            data-parsley-minlength-message="Please Enter A valid Number"
                            data-parsley-type="digits"
                            data-parsley-type-message="Only Numbers"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-field">
                          <select
                            className="form-control"
                            name="service"
                            required=""
                          >
                            <option className="hidden" selected="" disabled="">
                              {" "}
                              Select Services
                            </option>
                            {ourService.map((item) => {
                              return (
                                <option value={item.id}>{item.title}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <select
                            className="form-control"
                            name="country"
                            required=""
                          >
                            <option className="hidden" selected="" disabled="">
                              {" "}
                              Select Country
                            </option>
                            {CountryList.map((item) => {
                              return (
                                <option value={item.code}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-field">
                          <input
                            type="text"
                            placeholder="City"
                            id="city"
                            name="city"
                            required=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-field">
                      <textarea
                        placeholder="Message"
                        rows="7"
                        id="message"
                        name="msg"
                      ></textarea>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6Ld1ytgUAAAAABUAVvXQ8JDNvtE9SQDaPfrqW__q"
                          ></div>
                          <div id="g-recaptcha-error2"></div>
                        </div>
                      </div>
                    </div>
                    <div className="form-field">
                      <button
                        // type="submit"
                        name="submit"
                        className="readon submit"
                        // id="submit"
                      >
                        Submit Now
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <span id="contact-loding" style={{ display: "none" }}>
                        <i
                          className="fa fa-refresh fa-pulse"
                          aria-hidden="true"
                          style={{ fontSize: 30, color: "green" }}
                        ></i>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
