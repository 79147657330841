import React from "react";
import { useNavigate } from "react-router-dom";
import NavHeader from "../../../components/NavHeader";
import { Partners, keyStaff } from "../../../constant/servicesData";

const KeyStaff = () => {
  const navigate = useNavigate();
  return (
    <div>
      <NavHeader name={"Key Staff"} />
      <section className="collections-gid-janus pt-5 pb-5">
        <div className="container patn">
          <div className="row">
            {keyStaff.map((item) => {
              return (
                <div className="col-lg-6">
                  <div
                    className=" borders partners-team2 patn"
                    style={{ minHeight: 250 }}
                  >
                    <div className="row">
                      <div className="col-lg-4 progress-content-img founder-partners-img-width">
                        <div className="founder-partners-img">
                          <img
                            src={item.image}
                            alt="CA Sangeeta Singh"
                            className="img-fluid image1"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 progress-content-img">
                        <div className="founder-partners-img2">
                          <h3 style={{ fontSize: 24, textAlign: "left" }}>
                            {item.name}
                          </h3>
                          <ul style={{ marginTop: 20 }}>
                            <li>{item.qualification}</li>
                            {/* <li>M. No. {item.MNo}</li> */}
                            <li>{item.post}</li>
                            {/* <li>Mobile : {item.mobile}</li> */}
                            {/* <li>E-mail: {item.email}</li> */}
                          </ul>
                        </div>
                        {/* <div className="btn btn-dark btn--leftBorder btn--rightBorder  mt-3">
                          <a
                          // onClick={() =>
                          //   navigate(
                          //     `/partners/${item.name}`.replace(/\s/g, ""),
                          //     {
                          //       state: { data: item },
                          //     }
                          //   )
                          // }
                          >
                            Explore More
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default KeyStaff;
