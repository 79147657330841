import React from "react";
import NavHeader from "../../components/NavHeader";
import "./style.css";
import { ourTeam } from "../../constant/servicesData";

const OurTeam = () => {
  return (
    <div>
      <NavHeader name={"Our Team"} />
      <section className="collections-gid-janus  pt-5 pb-5">
        <div className="container patn">
          <div className="row">
            {ourTeam.map((item) => {
              return (
                <div className="col-md-3 text-center">
                  <div
                    className=" patner-className borders"
                    style={{ height: 500 }}
                  >
                    <a href="" className="">
                      {" "}
                      <img
                        src={item.image}
                        alt=" Image"
                        className="img-fluid"
                      />
                    </a>
                    <h3 style={{ marginTop: 10 }}>{item.title}</h3>
                    <p
                      style={{
                        marginTop: 10,
                        textAlign: "center",
                        fontWeight: "400",
                      }}
                    >
                      {item.description}
                    </p>
                    <div className="btn btn-dark  pt-20">
                      <a href={item.url} className="">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurTeam;
