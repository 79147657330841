import React from "react";
import NavHeader from "../../../components/NavHeader";

const InternationalTaxation = () => {
  return (
    <div>
      <NavHeader name={"International Taxation"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2021/07/Untitled-1.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">International Taxation</h3>

                <ul>
                  <li>Determining taxability on international transactions</li>
                  <li>
                    Structuring international transactions including capital
                    transactions for efficient tax planning
                  </li>
                  <li>
                    Assisting in structuring business in a tax–efficient manner
                    by offering strategies and solutions which are in line with
                    objectives
                  </li>
                  <li>Compliance with FEMA and RBI regulations</li>
                  <li>
                    Determining benefits arising out of Double Taxation
                    Avoidance Agreements
                  </li>
                  <li>
                    Obtaining TDS Exemption Certificates, Tax Residency
                    Certificates
                  </li>
                  <li>Tax Clearance Certificate, Form 30A</li>
                  <li>
                    FATCA &#038; CRS Services, including classification, due
                    diligence, reporting, health checks etc.
                  </li>
                  <li>Transfer Pricing Assignments</li>
                  <li>Assisting in preparation of Master File</li>
                  <li>Assistance with CbCR reporting requirement</li>
                  <li>Consultancy and advisory</li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InternationalTaxation;
