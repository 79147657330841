import React from "react";
import NavHeader from "../../../components/NavHeader";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { CharteredAccountants } from "../../../constant/servicesData";

const Chartered = () => {
  const navigate = useNavigate();
  return (
    <div>
      <NavHeader name={"CHARTERED ACCOUNTANTS"} />
      <section class="collections-gid-janus  pt-5 pb-5">
        <div class="container patn">
          <div class="row">
            {CharteredAccountants.map((item) => {
              return (
                <div class="col-lg-6">
                  <div class=" borders partners-team2 patn">
                    <div class="row">
                      <div class="col-lg-4 progress-content-img">
                        <div class="founder-partners-img founder-partners-img-width">
                          <img
                            src={item.image}
                            alt="Mamta Nigam"
                            class="img-fluid image1"
                          />
                        </div>
                      </div>
                      <div class="col-lg-8 progress-content-img">
                        <div class="founder-partners-img2">
                          <h3>{item.name} </h3>
                          <ul>
                            <li>{item.qualification}</li>
                            <li>{item.post}</li>
                            <li>Mobile : {item.mobile}</li>
                            <li>E-mail: {item.email}</li>
                          </ul>
                        </div>
                        <div class="btn btn-dark btn--leftBorder btn--rightBorder  mt-3">
                          <a
                            onClick={() =>
                              navigate(
                                `/partners/${item.name}`.replace(/\s/g, ""),
                                {
                                  state: { data: item },
                                }
                              )
                            }
                          >
                            Explore More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section
        class="about py-md-5 py-5 rs-about-lawyer rs-about-lawyer1"
        id="about"
        style={{ background: "#F5F5F5" }}
      >
        <div class="container">
          <div class="row rs-vertical-middle">
            <div class="col-lg-12  wow fadeInLeft" data-wow-duration="1s">
              <div class="rs-left-border-title">
                <h2 class="title-w3pvt mb-3">Chartered Accountants in Delhi</h2>
              </div>
              <div
                class="des"
                //   style="overflow:auto; height:140px; padding:0px 20px 10px 0px"
                style={{ overflow: "auto", height: 140, padding: 10 }}
              >
                <p>
                  Today, the Best Chartered Accountants in Delhi are the need of
                  the hour. Whether it’s Auditing, Budget Forecasting, Tax
                  Planning, Finalising of Books of Accounts, Capital Budgeting,
                  Financing or any other related activities, need of the best
                  Chartered Accountants in Delhi is a must. Nowadays trending
                  topic of current scenario is implementation of GST and various
                  problems faced by all the taxpayers as GST is highly dynamic,
                  Top Chartered Accountants in Delhi are playing a vital role in
                  its implementation.
                </p>
                <p>
                  Top Chartered Accountants in Delhi play an eminent role in
                  various field. The knowledgeable Chartered Accountant firms in
                  Delhi are very much required and is a pre- requisite in the
                  formation of any law relating to commerce, taxation or legal
                  fields. While forming a law or any act, Chartered Accountant
                  firms in Delhi are one of the most important pillar. They have
                  comprehensive knowledge of various important aspects which are
                  important in the formation of any law and that’s how Chartered
                  Accountants function for the Economy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Chartered;
