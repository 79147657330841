import React from "react";
import NavHeader from "../../../components/NavHeader";

const Forensic = () => {
  return (
    <div>
      <NavHeader name={"Forensic Audit Services"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2021/08/resize-2.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Forensic Audit Services</h3>
                <ul>
                  <li>
                    Reviewing financial documents to identify inaccuracies
                  </li>
                  <li>Analyzing financial data and tracing discrepancies</li>
                  <li>Forecasting and preventing financial frauds</li>
                  <li>Due Diligence</li>
                  <li>Fraud Risk Assessment</li>
                  <li>Information Security Risk Assessment</li>
                  <li>Asset Tracing and misappropriation</li>
                  <li>Vendor monitoring</li>
                  <li>Litigation Support</li>
                  <li>Money laundering Compliance</li>
                </ul>
                <p>
                  &nbsp;
                  <br />
                  <strong>
                    Matters related to compliance of Money Laundering Act 2003,
                    this compliance programme includes:
                  </strong>{" "}
                </p>
                <ul>
                  <li> Compliance regulations</li>
                  <li>Designation of a Compliance officer</li>
                  <li> Independent Testing</li>
                  <li>Training</li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Forensic;
