import React from "react";
import NavHeader from "../../../components/NavHeader";
import "./style.css";
import { Empanelments } from "../../../constant/servicesData";

const Empanelment = () => {
  return (
    <div>
      <NavHeader name={"Empanelment"} />
      <section class="emplanement-cont pt-5">
        <div class="container">
          <div class="row  text-center contact-address-section">
            {Empanelments.map((item) => {
              return (
                <div class="col-md-6 pbb wow fadeInUp" data-wow-duration="1s">
                  <div class="important2 borders">
                    <img src={item.image} class="img-fluid" alt="logo" />
                    <h4>{item.name}</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Empanelment;
