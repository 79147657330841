import React from "react";
import NavHeader from "../../../components/NavHeader";

const Startup = () => {
  return (
    <div>
      <NavHeader name={"Startup Services"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/STARTUP-SERVICES.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Startup Services</h3>
                <p>
                  Startup Services in India takes a lot of confidence and grit
                  and we are here to back you up with the right financial advice
                  and road map. Your ultimate goal while opening any startup is
                  to reap maximum profit and that is what our services help you
                  achieve. Time is money and no one understands that better than
                  us! Get started on the right path with your startup and let us
                  fuel your dreams and aspirations with the best of financial
                  services.{" "}
                </p>
                <p>
                  <b>Startup Services in India</b>
                </p>
                <ul>
                  <li>
                    Assistance in identifying the right business structure
                  </li>
                  <li>Obtaining Business licenses</li>
                  <li>
                    Incorporation of Company and LLP (Limited Liability
                    Partnership)
                  </li>
                  <li>Setting up Policies and Procedures</li>
                </ul>

                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Startup;
