import React from "react";
import NavHeader from "../../../components/NavHeader";

const Finance = () => {
  return (
    <div>
      <NavHeader name={"Finance"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/FINANCE.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Finance</h3>
                <p>
                  At P Dhaval &#038; Co. we focus on the future of finance, the
                  evolving market trends, and how this goes into shaping every
                  venture, big or small. Working with clients, giving a
                  uniformed structure to their business goals and helping them
                  execute their plans in an effective way is what sets us apart
                  from other Chartered Accountant Firms. Whether it’s data,
                  technology or analytics, we do it all with a quick turnaround
                  time.{" "}
                </p>
                <ul>
                  <li>Preparation of project report</li>
                  <li>Preparation of CMA Data</li>
                  <li>
                    Loan arrangement- Cash credit limit, Term loan &#038;
                    Project loan
                  </li>
                  <li>Due diligence for finance</li>
                  <li>Valuation of Business</li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Finance;
