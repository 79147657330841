import React from "react";
import NavHeader from "../../../components/NavHeader";

const CACertification = () => {
  return (
    <div>
      <NavHeader name={"CA Certification"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/BUSINESS-PROCESS-OUTSOURCE-BPO.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">CA Certification</h3>

                <ul>
                  <li>
                    Certification of Networth for Visa Processing,Bank Finances,
                    Bank Guarantee
                  </li>
                  <li>Certification of Turnover of Business</li>
                  <li>Certification for RERA Purpose</li>
                  <li>
                    Certification of Funds Utilisation of Funds or Grant under
                    Charitiable Trust or CSR Activities
                  </li>
                  <li>Certifiation of Working Capital</li>
                  <li>
                    Certification issues for KYC purpose to Banks confirming
                    sole proprietorship
                  </li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CACertification;
