import React from "react";
import { useEffect } from "react";

export const ContentSection = ({ item }) => {
  console.log("item", item);
  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img src={item.image} alt="" class="img-fluid image1" />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">{item.title}</h3>
                <p>{item.desc}</p>
                <ul>
                  <li>{item.description}</li>
                  <li>{item.desc1}</li>
                  <li>{item.desc2}</li>
                  <li>{item.desc3}</li>
                  <li>{item.desc4}</li>
                  <li>{item.desc5}</li>
                  <li>{item.desc6}</li>
                  <li>{item.desc7}</li>
                  <li>{item.desc8}</li>
                  <li>{item.desc9}</li>
                  <li>{item.desc10}</li>
                  <li>{item.desc11}</li>
                  <li>{item.desc12}</li>
                  <li>{item.desc13}</li>
                  <li>{item.desc14}</li>
                  <li>{item.desc15}</li>
                  <li>{item.desc16}</li>
                  <li>{item.desc17}</li>
                  <li>{item.desc18}</li>
                  <li>{item.desc19}</li>
                  <li>{item.desc20}</li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
