import React from "react";
import NavHeader from "../../components/NavHeader";
import "./style.css";

const Gallery = () => {
  return (
    <div>
      <NavHeader name={"Gallery"} />
      <section class="collections-gid-janus gallery-pt" id="skills">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-12 px-lg-0 wow fadeInLeft "
              data-wow-duration="1s"
            >
              {/* <div class="col-md-12 dental-cont">
                <div class="video-preview">
                  <div class="row">
                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/managing-partner-ca-gopal-kumar-kedia-birthday-2022/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2023/02/2-1.jpg"
                            alt="Video Gallery"
                            class="img-fluid"
                          />
                          <figcaption> Birthday Celebrations</figcaption>
                        </figure>
                      </a>
                    </div>
                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/29th-foundation-day/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2022/07/DSC_3126.jpg"
                            alt="Video Gallery"
                            class="img-fluid"
                          />
                          <figcaption> Foundation Day</figcaption>
                        </figure>
                      </a>
                    </div>
                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/diwali-celebration-2022/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2022/10/14-3.jpg"
                            alt="Video Gallery"
                            class="img-fluid"
                          />
                          <figcaption> Diwali Celebration</figcaption>
                        </figure>
                      </a>
                    </div>
                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/holi-celebration-2022/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2022/03/IMG_20220317_172818.jpg"
                            alt="Video Gallery"
                            class="img-fluid"
                          />
                          <figcaption> Holi Celebration</figcaption>
                        </figure>
                      </a>
                    </div>
                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/happy-new-year-2023/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2023/01/4-2.jpg"
                            alt=" New Year Celebration"
                            class="img-fluid"
                          />
                          <figcaption> New Year Celebration</figcaption>
                        </figure>
                      </a>
                    </div>
                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/annual-picnic-2023/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2023/02/3-3.jpg"
                            alt="Video Gallery"
                            class="img-fluid"
                          />
                          <figcaption> Annual Picnic 2023</figcaption>
                        </figure>
                      </a>
                    </div>

                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/partners-gallery/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2019/06/11-2.jpg"
                            alt="Video Gallery"
                            class="img-fluid"
                          />
                          <figcaption> Partners</figcaption>
                        </figure>
                      </a>
                    </div>
                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/video-gallery/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2019/06/2-5.jpg"
                            alt="Video Gallery"
                            class="img-fluid"
                          />
                          <figcaption> Video Gallery</figcaption>
                        </figure>
                      </a>
                    </div>
                    <div
                      class="col-md-4 col-sm-12 gallery-container"
                      style={{ padding: 4 }}
                    >
                      <a
                        // href="https://gkkediaandco.com/electronic-media-coverage/"
                        class="info youtube cboxElement"
                        tabindex="0"
                      >
                        <figure>
                          <img
                            src="https://gkkediaandco.com/wp-content/uploads/2019/08/GK-Thumbnails.jpg"
                            alt="Video Gallery"
                            class="img-fluid"
                          />
                          <figcaption> Media Coverage</figcaption>
                        </figure>
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
