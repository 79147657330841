import React from "react";
import NavHeader from "../../../components/NavHeader";

const Company = () => {
  return (
    <div>
      <NavHeader name={"Company Law Matters Consultancy"} />
      <section class="collections-gid-janus  my-lg-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 p-lg-3 progress-content-img px-lg-0 wow fadeInUp"
              data-wow-duration="1s"
            >
              <img
                src="https://gkkediaandco.com/wp-content/uploads/2019/06/COMPANY-LAW.jpg"
                alt=""
                class="img-fluid image1"
              />
            </div>
            <div
              class="col-lg-7 progress-content service-call text-left  wow fadeInUp"
              data-wow-duration="2s"
            >
              <div class="p-lg-3">
                <h3 class="title-w3pvt2">Company Law Matters Consultancy</h3>
                <p>
                  Whether it is the formation of Indian or offshore
                  organizations, clients require thorough assistance with
                  several aspects related to business. Strategic planning is as
                  important as risk analysis and future profitability and
                  therefore we provide seamless services in Company Law matters
                  that can help you pave the way to a successful and lucrative
                  tomorrow!{" "}
                </p>
                <ul>
                  <li>
                    Formation of company in India, viz. Private Limited, Public
                    Limited, wholly owned subsidiary company, One Person Company
                    (OPC), Limited Liability Partnership (LLP), etc.
                  </li>
                  <li>
                    All matters related with Registrar of Companies (ROC),
                    Regional Director (RD) &#038; National Company Law Tribunal
                    (NCLT)
                  </li>
                  <li>Maintenance &#038; compliance of Statutory Records</li>
                  <li>Valuation of Equity Shares</li>
                  <li>XBRL filings</li>
                  <li>Consultancy in above matters</li>
                </ul>
                <div
                  class="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                  style={{ width: "129px", float: "left" }}
                >
                  <a
                    href="/contact"
                    id="ServicepageEnquireNow"
                    class="ServicepageEnquireNow reg"
                  >
                    Enquire Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Company;
