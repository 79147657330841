import React from "react";
import "./style.css";
import { Carousel } from "react-bootstrap";
import Banner1 from "../../assets/images/banner-1.jpg";
import Banner2 from "../../assets/images/banner-2.png";
import Banner3 from "../../assets/images/banner-3.png";

const LiveUpdates = () => {
  // const images = [
  //   {
  //     url: "https://gkkediaandco.com/wp-content/uploads/2023/02/passout-1598x433.jpeg",
  //   },
  //   {
  //     url: "https://gkkediaandco.com/wp-content/uploads/2023/01/banners-1277x346.jpeg",
  //   },
  //   {
  //     url: "https://gkkediaandco.com/wp-content/uploads/2023/01/gkkedia_banner-1-1277x346.jpeg",
  //   },
  //   {
  //     url: "https://gkkediaandco.com/wp-content/uploads/2023/01/gkkedia_banner-2-1277x346.jpeg",
  //   },
  // ];
  return (
    <div>
      <section className="about2 update-bg2 " id="about">
        <div className="container ">
          <div className="row">
            <div
              className="col-lg-12 gd-bottom  text-center wow fadeInUp"
              data-wow-duration="3s"
            >
              <div className="live-cantt">
                <h3 className="title-w3pvt">Live Updates</h3>
                <div className="pb-3 explore-button">
                  <a href="/live-updates/" style={{ textDecoration: "none" }}>
                    Explore More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <SimpleImageSlider
            width={"100%"}
            height={455}
            images={images}
            showBullets={true}
            showNavs={true}
            loop={true}           
          /> */}
          <Carousel>
            <Carousel.Item interval={2000}>
              <img
                className="d-block w-100"
                src={Banner1}
                alt="First slide"
                style={{ height: 410 }}
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img className="d-block w-100" src={Banner2} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img className="d-block w-100" src={Banner3} alt="Third slide" />
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
    </div>
  );
};

export default LiveUpdates;
