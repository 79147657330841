import React from "react";
// import "./style.css";
import { useNavigate } from "react-router-dom";
import NavHeader from "../../../components/NavHeader";
import { Servicesdata, ourService } from "../../../constant/servicesData";

const Blogs = () => {
  const navigate = useNavigate();

  return (
    <div>
      <NavHeader name={"Blogs"} />
      <section
        className="collections-gid-janus  pt-5 pb-5"
        style={{ background: "#f0f0f0" }}
      >
        <div className="container clearfix" id="#content">
          <div className="row">
            <div className="col-lg-3 sidebar" id="sidebar">
              <div className="card  sidebar__inner">
                <div className="card-header bg-primary ">
                  <span className="text-white fs-2x">Our Services</span>
                </div>
                {ourService.map((item) => {
                  return (
                    <div className="card-body p-0">
                      <ul className="LeftMenu">
                        <li>
                          <a href={`#${item.title}`.replace(/\s/g, "")}>
                            {item.title}
                          </a>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-md-9">
              {Servicesdata.map((item) => {
                return (
                  <div
                    className=" clearfix wow fadeInUp borders"
                    data-wow-duration="1s"
                    id={`${item.title}`.replace(/\s/g, "")}
                  >
                    <div className="col-lg-4 p-lg-3 mt-5 progress-content-img px-lg-0  float-right ">
                      <img
                        src={item.image}
                        alt=""
                        className="img-fluid image1"
                      />
                    </div>

                    <div className="col-lg-8 service-call progress-content float-left ">
                      <div className="p-lg-3 ">
                        <h3 className="title-w3pvt2">{item.title}</h3>

                        <div className="service-scroll">
                          <ul>
                            <li>{item.description}</li>
                            <li>{item.desc1}</li>
                            <li>{item.desc2}</li>
                          </ul>
                        </div>

                        <div className="progress-w3pvt mt-2">
                          <div
                            className="btn btn-dark btn--leftBorder btn--rightBorder  pt-20"
                            style={{ width: "129px", float: "left" }}
                          >
                            <a href="/contact" className="reg">
                              Enquire Now
                            </a>
                          </div>
                          <div className="btn btn-dark btn--leftBorder btn--rightBorder  pt-20">
                            <a
                              onClick={() =>
                                navigate(
                                  `/services/${item.title}`.replace(/\s/g, ""),
                                  {
                                    state: { data: item },
                                  }
                                )
                              }
                            >
                              Explore More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blogs;
