import React from "react";
import "./style.css";

const NavHeader = ({ name }) => {
  return (
    <div className="rs-breadcrumbs sec-color">
      <div className="breadcrumbs-image">
        <img
          src="https://gkkediaandco.com/wp-content/themes/gkkedia/images/about.jpg"
          alt=" Image"
          className="img-fluid"
        />
        <div className="breadcrumbs-inner">
          <div className="container">
            <div
              className="breadcrumbs-text wow fadeInUp"
              data-wow-duration="1s"
            >
              <h1 className="breadcrumbs-title">{name}</h1>
              <ul className="breadcrumbs-subtitle">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>{name}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
